.section1 .title-t:after, .section1 .cont:after, .section2 .cont .top-text:after, .section2 .cont .swiper-cont:after, .section2 .cont .swiper-cont .right .item:after, .section3 .cont .left .num:after, .section4 .index-pd .top-text:after, .section4 .index-pd .cont:after, .section5 .top-text:after, .section5 .logo-list ul:after {
  content: "";
  display: block;
  clear: both;
}

*::-webkit-scrollbar {
  width: 0px !important;
  scrollbar-width: thin;
}

.wow {
  visibility: visible !important;
}

@media (max-width: 1024px) {
  .wow {
    visibility: hidden;
  }
}

.index-pd {
  padding-top: 100px;
  padding-left: 120px;
  padding-right: 160px;
  padding-bottom: 0px;
}

@media (max-width: 1024px) {
  .index-pd {
    padding-top: 70px;
  }
}

@media (max-width: 767px) {
  .index-pd {
    padding-top: 50px;
  }
}

@media (max-width: 1699px) {
  .index-pd {
    padding-left: 105.71429px;
  }
}

@media (max-width: 1600px) {
  .index-pd {
    padding-left: 91.42857px;
  }
}

@media (max-width: 1366px) {
  .index-pd {
    padding-left: 77.14286px;
  }
}

@media (max-width: 1289px) {
  .index-pd {
    padding-left: 62.85714px;
  }
}

@media (max-width: 1024px) {
  .index-pd {
    padding-left: 48.57143px;
  }
}

@media (max-width: 991px) {
  .index-pd {
    padding-left: 34.28571px;
  }
}

@media (max-width: 767px) {
  .index-pd {
    padding-left: 20px;
  }
}

@media (max-width: 1699px) {
  .index-pd {
    padding-right: 140px;
  }
}

@media (max-width: 1600px) {
  .index-pd {
    padding-right: 120px;
  }
}

@media (max-width: 1366px) {
  .index-pd {
    padding-right: 100px;
  }
}

@media (max-width: 1289px) {
  .index-pd {
    padding-right: 80px;
  }
}

@media (max-width: 1024px) {
  .index-pd {
    padding-right: 60px;
  }
}

@media (max-width: 991px) {
  .index-pd {
    padding-right: 40px;
  }
}

@media (max-width: 767px) {
  .index-pd {
    padding-right: 20px;
  }
}

@media (max-width: 1024px) {
  .index-pd {
    padding-bottom: 60px;
  }
}

@media (max-width: 767px) {
  .index-pd {
    padding-bottom: 30px;
  }
}

.index-common-title {
  font-size: 20px;
}

@media (max-width: 1699px) {
  .index-common-title {
    font-size: 19.42857px;
  }
}

@media (max-width: 1600px) {
  .index-common-title {
    font-size: 18.85714px;
  }
}

@media (max-width: 1366px) {
  .index-common-title {
    font-size: 18.28571px;
  }
}

@media (max-width: 1289px) {
  .index-common-title {
    font-size: 17.71429px;
  }
}

@media (max-width: 1024px) {
  .index-common-title {
    font-size: 17.14286px;
  }
}

@media (max-width: 991px) {
  .index-common-title {
    font-size: 16.57143px;
  }
}

@media (max-width: 767px) {
  .index-common-title {
    font-size: 16px;
  }
}

.index-common-title h1 {
  font-weight: normal;
  font-size: 32px;
  margin-bottom: 10px;
}

@media (max-width: 991px) {
  .index-common-title h1 {
    font-weight: bold;
  }
}

@media (max-width: 1699px) {
  .index-common-title h1 {
    font-size: 30.85714px;
  }
}

@media (max-width: 1600px) {
  .index-common-title h1 {
    font-size: 29.71429px;
  }
}

@media (max-width: 1366px) {
  .index-common-title h1 {
    font-size: 28.57143px;
  }
}

@media (max-width: 1289px) {
  .index-common-title h1 {
    font-size: 27.42857px;
  }
}

@media (max-width: 1024px) {
  .index-common-title h1 {
    font-size: 26.28571px;
  }
}

@media (max-width: 991px) {
  .index-common-title h1 {
    font-size: 25.14286px;
  }
}

@media (max-width: 767px) {
  .index-common-title h1 {
    font-size: 24px;
  }
}

@media (max-width: 1699px) {
  .index-common-title h1 {
    margin-bottom: 9.28571px;
  }
}

@media (max-width: 1600px) {
  .index-common-title h1 {
    margin-bottom: 8.57143px;
  }
}

@media (max-width: 1366px) {
  .index-common-title h1 {
    margin-bottom: 7.85714px;
  }
}

@media (max-width: 1289px) {
  .index-common-title h1 {
    margin-bottom: 7.14286px;
  }
}

@media (max-width: 1024px) {
  .index-common-title h1 {
    margin-bottom: 6.42857px;
  }
}

@media (max-width: 991px) {
  .index-common-title h1 {
    margin-bottom: 5.71429px;
  }
}

@media (max-width: 767px) {
  .index-common-title h1 {
    margin-bottom: 5px;
  }
}

.section {
  overflow: hidden;
  height: 100vh;
}

@media (max-width: 1024px) {
  .section {
    height: auto;
  }
}

.index-banner {
  overflow: hidden;
  position: relative;
  height: 100vh;
}

.index-banner .swiper {
  height: 100%;
  overflow: hidden;
}

.index-banner .swiper .swiper-slide {
  overflow: hidden;
  position: relative;
}

.index-banner .swiper .swiper-slide.ani-slide b {
  transform: scale(1);
}

.index-banner .swiper .swiper-slide.ani-slide .pc {
  display: block;
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide.ani-slide .pc {
    display: none;
  }
}

.index-banner .swiper .swiper-slide.ani-slide .mobile {
  display: none;
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide.ani-slide .mobile {
    display: block;
  }
}

.index-banner .swiper .swiper-slide.ani-slide .t {
  opacity: 1;
  margin-top: 0;
}

.index-banner .swiper .swiper-slide::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
}

.index-banner .swiper .swiper-slide .video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: block;
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-slide .video {
    display: none;
  }
}

.index-banner .swiper .swiper-slide .video video {
  width: 100%;
  height: 100%;
  transform: scale(1.2);
  transform-origin: center;
}

@media screen and (max-width: 1366px) {
  .index-banner .swiper .swiper-slide .video video {
    transform: scale(1.3);
  }
}

.index-banner .swiper .swiper-slide b {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: center;
  background-size: cover;
  background-position: center;
  transform: scale(1.2);
  transition: all 5s;
  z-index: 0;
}

.index-banner .swiper .swiper-slide .t {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
  color: #fff;
  line-height: 1.3;
  transform: translate3d(-50%, -50%, 0);
  transition: all 1s;
  opacity: 0;
  transition-delay: .2s;
  width: 100%;
  box-sizing: border-box;
  padding-left: 160px;
  margin-top: 100px;
  font-size: 54px;
}

@media (max-width: 1699px) {
  .index-banner .swiper .swiper-slide .t {
    padding-left: 144.28571px;
  }
}

@media (max-width: 1600px) {
  .index-banner .swiper .swiper-slide .t {
    padding-left: 128.57143px;
  }
}

@media (max-width: 1366px) {
  .index-banner .swiper .swiper-slide .t {
    padding-left: 112.85714px;
  }
}

@media (max-width: 1289px) {
  .index-banner .swiper .swiper-slide .t {
    padding-left: 97.14286px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-slide .t {
    padding-left: 81.42857px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-slide .t {
    padding-left: 65.71429px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .t {
    padding-left: 50px;
  }
}

@media (max-width: 1699px) {
  .index-banner .swiper .swiper-slide .t {
    font-size: 49.14286px;
  }
}

@media (max-width: 1600px) {
  .index-banner .swiper .swiper-slide .t {
    font-size: 44.28571px;
  }
}

@media (max-width: 1366px) {
  .index-banner .swiper .swiper-slide .t {
    font-size: 39.42857px;
  }
}

@media (max-width: 1289px) {
  .index-banner .swiper .swiper-slide .t {
    font-size: 34.57143px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-slide .t {
    font-size: 29.71429px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-slide .t {
    font-size: 24.85714px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .t {
    font-size: 20px;
  }
}

@media screen and (max-width: 767px) {
  .index-banner .swiper .swiper-slide .t {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.index-banner .sroll-icon {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  white-space: nowrap;
  z-index: 2;
  display: block;
  margin-left: 80px;
}

@media (max-width: 1024px) {
  .index-banner .sroll-icon {
    display: none;
  }
}

@media (max-width: 1699px) {
  .index-banner .sroll-icon {
    margin-left: 72.14286px;
  }
}

@media (max-width: 1600px) {
  .index-banner .sroll-icon {
    margin-left: 64.28571px;
  }
}

@media (max-width: 1366px) {
  .index-banner .sroll-icon {
    margin-left: 56.42857px;
  }
}

@media (max-width: 1289px) {
  .index-banner .sroll-icon {
    margin-left: 48.57143px;
  }
}

@media (max-width: 1024px) {
  .index-banner .sroll-icon {
    margin-left: 40.71429px;
  }
}

@media (max-width: 991px) {
  .index-banner .sroll-icon {
    margin-left: 32.85714px;
  }
}

@media (max-width: 767px) {
  .index-banner .sroll-icon {
    margin-left: 25px;
  }
}

.index-banner .sroll-icon::before {
  content: '';
  display: block;
  width: 1px;
  position: absolute;
  left: 50%;
  bottom: 200%;
  background-color: #fff;
  animation: scrollrain 3s infinite;
  height: 80px;
}

@media (max-width: 1699px) {
  .index-banner .sroll-icon::before {
    height: 74.28571px;
  }
}

@media (max-width: 1600px) {
  .index-banner .sroll-icon::before {
    height: 68.57143px;
  }
}

@media (max-width: 1366px) {
  .index-banner .sroll-icon::before {
    height: 62.85714px;
  }
}

@media (max-width: 1289px) {
  .index-banner .sroll-icon::before {
    height: 57.14286px;
  }
}

@media (max-width: 1024px) {
  .index-banner .sroll-icon::before {
    height: 51.42857px;
  }
}

@media (max-width: 991px) {
  .index-banner .sroll-icon::before {
    height: 45.71429px;
  }
}

@media (max-width: 767px) {
  .index-banner .sroll-icon::before {
    height: 40px;
  }
}

.section1 {
  background-color: #edecec;
  position: relative;
}

.section1 .bg-img {
  height: 100%;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 55.2%;
}

@media (max-width: 1024px) {
  .section1 .bg-img {
    width: 100%;
  }
}

.section1 .bg-img::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
}

.section1 .bg-img::after {
  content: '';
  display: block;
  width: 50%;
  height: 100%;
  background-image: linear-gradient(left, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  position: absolute;
  top: 0;
  left: 0;
}

.section1 .title-t {
  position: relative;
  z-index: 2;
  padding-left: 120px;
  padding-right: 160px;
  padding-top: 100px;
  margin-bottom: 20px;
}

@media (max-width: 1699px) {
  .section1 .title-t {
    padding-left: 105.71429px;
  }
}

@media (max-width: 1600px) {
  .section1 .title-t {
    padding-left: 91.42857px;
  }
}

@media (max-width: 1366px) {
  .section1 .title-t {
    padding-left: 77.14286px;
  }
}

@media (max-width: 1289px) {
  .section1 .title-t {
    padding-left: 62.85714px;
  }
}

@media (max-width: 1024px) {
  .section1 .title-t {
    padding-left: 48.57143px;
  }
}

@media (max-width: 991px) {
  .section1 .title-t {
    padding-left: 34.28571px;
  }
}

@media (max-width: 767px) {
  .section1 .title-t {
    padding-left: 20px;
  }
}

@media (max-width: 1699px) {
  .section1 .title-t {
    padding-right: 140px;
  }
}

@media (max-width: 1600px) {
  .section1 .title-t {
    padding-right: 120px;
  }
}

@media (max-width: 1366px) {
  .section1 .title-t {
    padding-right: 100px;
  }
}

@media (max-width: 1289px) {
  .section1 .title-t {
    padding-right: 80px;
  }
}

@media (max-width: 1024px) {
  .section1 .title-t {
    padding-right: 60px;
  }
}

@media (max-width: 991px) {
  .section1 .title-t {
    padding-right: 40px;
  }
}

@media (max-width: 767px) {
  .section1 .title-t {
    padding-right: 20px;
  }
}

@media (max-width: 1024px) {
  .section1 .title-t {
    padding-top: 100px;
  }
}

@media (max-width: 767px) {
  .section1 .title-t {
    padding-top: 50px;
  }
}

@media (max-width: 1699px) {
  .section1 .title-t {
    margin-bottom: 18.57143px;
  }
}

@media (max-width: 1600px) {
  .section1 .title-t {
    margin-bottom: 17.14286px;
  }
}

@media (max-width: 1366px) {
  .section1 .title-t {
    margin-bottom: 15.71429px;
  }
}

@media (max-width: 1289px) {
  .section1 .title-t {
    margin-bottom: 14.28571px;
  }
}

@media (max-width: 1024px) {
  .section1 .title-t {
    margin-bottom: 12.85714px;
  }
}

@media (max-width: 991px) {
  .section1 .title-t {
    margin-bottom: 11.42857px;
  }
}

@media (max-width: 767px) {
  .section1 .title-t {
    margin-bottom: 10px;
  }
}

.section1 .title-t .left {
  color: #fff;
  float: left;
  width: 60%;
}

@media (max-width: 767px) {
  .section1 .title-t .left {
    float: none;
  }
}

@media (max-width: 767px) {
  .section1 .title-t .left {
    width: 100%;
  }
}

.section1 .title-t .right {
  line-height: 1.4;
  font-weight: normal;
  color: #221f1f;
  float: right;
  width: 40%;
  font-size: 32px;
  margin-top: 0;
}

@media (max-width: 1024px) {
  .section1 .title-t .right {
    color: #fff;
  }
}

@media (max-width: 767px) {
  .section1 .title-t .right {
    float: none;
  }
}

@media (max-width: 767px) {
  .section1 .title-t .right {
    width: 100%;
  }
}

@media (max-width: 1699px) {
  .section1 .title-t .right {
    font-size: 29.71429px;
  }
}

@media (max-width: 1600px) {
  .section1 .title-t .right {
    font-size: 27.42857px;
  }
}

@media (max-width: 1366px) {
  .section1 .title-t .right {
    font-size: 25.14286px;
  }
}

@media (max-width: 1289px) {
  .section1 .title-t .right {
    font-size: 22.85714px;
  }
}

@media (max-width: 1024px) {
  .section1 .title-t .right {
    font-size: 20.57143px;
  }
}

@media (max-width: 991px) {
  .section1 .title-t .right {
    font-size: 18.28571px;
  }
}

@media (max-width: 767px) {
  .section1 .title-t .right {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .section1 .title-t .right {
    margin-top: 20px;
  }
}

.section1 .cont {
  position: relative;
  z-index: 2;
  padding-left: 120px;
}

@media (max-width: 1699px) {
  .section1 .cont {
    padding-left: 105.71429px;
  }
}

@media (max-width: 1600px) {
  .section1 .cont {
    padding-left: 91.42857px;
  }
}

@media (max-width: 1366px) {
  .section1 .cont {
    padding-left: 77.14286px;
  }
}

@media (max-width: 1289px) {
  .section1 .cont {
    padding-left: 62.85714px;
  }
}

@media (max-width: 1024px) {
  .section1 .cont {
    padding-left: 48.57143px;
  }
}

@media (max-width: 991px) {
  .section1 .cont {
    padding-left: 34.28571px;
  }
}

@media (max-width: 767px) {
  .section1 .cont {
    padding-left: 20px;
  }
}

@media (max-width: 1024px) {
  .section1 .cont {
    padding-bottom: 100px;
  }
}

@media (max-width: 767px) {
  .section1 .cont {
    padding-bottom: 60px;
  }
}

.section1 .cont .left-t {
  color: #fff;
  float: left;
  width: 26%;
  margin-top: 80px;
  padding-right: 0;
}

@media (max-width: 1024px) {
  .section1 .cont .left-t {
    float: none;
  }
}

@media (max-width: 1289px) {
  .section1 .cont .left-t {
    width: 34%;
  }
}

@media (max-width: 1024px) {
  .section1 .cont .left-t {
    width: 100%;
  }
}

@media (max-width: 1699px) {
  .section1 .cont .left-t {
    margin-top: 74.28571px;
  }
}

@media (max-width: 1600px) {
  .section1 .cont .left-t {
    margin-top: 68.57143px;
  }
}

@media (max-width: 1366px) {
  .section1 .cont .left-t {
    margin-top: 62.85714px;
  }
}

@media (max-width: 1289px) {
  .section1 .cont .left-t {
    margin-top: 57.14286px;
  }
}

@media (max-width: 1024px) {
  .section1 .cont .left-t {
    margin-top: 51.42857px;
  }
}

@media (max-width: 991px) {
  .section1 .cont .left-t {
    margin-top: 45.71429px;
  }
}

@media (max-width: 767px) {
  .section1 .cont .left-t {
    margin-top: 40px;
  }
}

@media (max-width: 1024px) {
  .section1 .cont .left-t {
    padding-right: 10%;
  }
}

@media screen and (max-width: 1366px) {
  .section1 .cont .left-t {
    margin-top: 40px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1699px) {
  .section1 .cont .left-t {
    margin-top: 37.14286px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1600px) {
  .section1 .cont .left-t {
    margin-top: 34.28571px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1366px) {
  .section1 .cont .left-t {
    margin-top: 31.42857px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1289px) {
  .section1 .cont .left-t {
    margin-top: 28.57143px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1024px) {
  .section1 .cont .left-t {
    margin-top: 25.71429px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 991px) {
  .section1 .cont .left-t {
    margin-top: 22.85714px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 767px) {
  .section1 .cont .left-t {
    margin-top: 20px;
  }
}

.section1 .cont .left-t .title {
  font-size: 20px;
  margin-bottom: 10px;
}

@media (max-width: 1699px) {
  .section1 .cont .left-t .title {
    font-size: 19.71429px;
  }
}

@media (max-width: 1600px) {
  .section1 .cont .left-t .title {
    font-size: 19.42857px;
  }
}

@media (max-width: 1366px) {
  .section1 .cont .left-t .title {
    font-size: 19.14286px;
  }
}

@media (max-width: 1289px) {
  .section1 .cont .left-t .title {
    font-size: 18.85714px;
  }
}

@media (max-width: 1024px) {
  .section1 .cont .left-t .title {
    font-size: 18.57143px;
  }
}

@media (max-width: 991px) {
  .section1 .cont .left-t .title {
    font-size: 18.28571px;
  }
}

@media (max-width: 767px) {
  .section1 .cont .left-t .title {
    font-size: 18px;
  }
}

@media (max-width: 1699px) {
  .section1 .cont .left-t .title {
    margin-bottom: 9.28571px;
  }
}

@media (max-width: 1600px) {
  .section1 .cont .left-t .title {
    margin-bottom: 8.57143px;
  }
}

@media (max-width: 1366px) {
  .section1 .cont .left-t .title {
    margin-bottom: 7.85714px;
  }
}

@media (max-width: 1289px) {
  .section1 .cont .left-t .title {
    margin-bottom: 7.14286px;
  }
}

@media (max-width: 1024px) {
  .section1 .cont .left-t .title {
    margin-bottom: 6.42857px;
  }
}

@media (max-width: 991px) {
  .section1 .cont .left-t .title {
    margin-bottom: 5.71429px;
  }
}

@media (max-width: 767px) {
  .section1 .cont .left-t .title {
    margin-bottom: 5px;
  }
}

.section1 .cont .left-t .desc {
  font-size: 16px;
  margin-bottom: 35px;
}

@media (max-width: 1699px) {
  .section1 .cont .left-t .desc {
    margin-bottom: 32.14286px;
  }
}

@media (max-width: 1600px) {
  .section1 .cont .left-t .desc {
    margin-bottom: 29.28571px;
  }
}

@media (max-width: 1366px) {
  .section1 .cont .left-t .desc {
    margin-bottom: 26.42857px;
  }
}

@media (max-width: 1289px) {
  .section1 .cont .left-t .desc {
    margin-bottom: 23.57143px;
  }
}

@media (max-width: 1024px) {
  .section1 .cont .left-t .desc {
    margin-bottom: 20.71429px;
  }
}

@media (max-width: 991px) {
  .section1 .cont .left-t .desc {
    margin-bottom: 17.85714px;
  }
}

@media (max-width: 767px) {
  .section1 .cont .left-t .desc {
    margin-bottom: 15px;
  }
}

.section1 .cont .left-t .p {
  font-size: 14px;
}

.section1 .cont .left-t .p p {
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
}

@media (max-width: 1699px) {
  .section1 .cont .left-t .p p {
    margin-bottom: 9.28571px;
  }
}

@media (max-width: 1600px) {
  .section1 .cont .left-t .p p {
    margin-bottom: 8.57143px;
  }
}

@media (max-width: 1366px) {
  .section1 .cont .left-t .p p {
    margin-bottom: 7.85714px;
  }
}

@media (max-width: 1289px) {
  .section1 .cont .left-t .p p {
    margin-bottom: 7.14286px;
  }
}

@media (max-width: 1024px) {
  .section1 .cont .left-t .p p {
    margin-bottom: 6.42857px;
  }
}

@media (max-width: 991px) {
  .section1 .cont .left-t .p p {
    margin-bottom: 5.71429px;
  }
}

@media (max-width: 767px) {
  .section1 .cont .left-t .p p {
    margin-bottom: 5px;
  }
}

.section1 .cont .left-t .p p::before {
  content: '\e633';
  font-family: 'iconfont';
  position: absolute;
  left: 0;
  color: #ff7800;
  top: -5px;
  font-size: 18px;
}

@media (max-width: 1699px) {
  .section1 .cont .left-t .p p::before {
    font-size: 17.71429px;
  }
}

@media (max-width: 1600px) {
  .section1 .cont .left-t .p p::before {
    font-size: 17.42857px;
  }
}

@media (max-width: 1366px) {
  .section1 .cont .left-t .p p::before {
    font-size: 17.14286px;
  }
}

@media (max-width: 1289px) {
  .section1 .cont .left-t .p p::before {
    font-size: 16.85714px;
  }
}

@media (max-width: 1024px) {
  .section1 .cont .left-t .p p::before {
    font-size: 16.57143px;
  }
}

@media (max-width: 991px) {
  .section1 .cont .left-t .p p::before {
    font-size: 16.28571px;
  }
}

@media (max-width: 767px) {
  .section1 .cont .left-t .p p::before {
    font-size: 16px;
  }
}

.section1 .cont .left-t .link {
  display: flex;
  margin-top: 60px;
}

@media (max-width: 1699px) {
  .section1 .cont .left-t .link {
    margin-top: 55.71429px;
  }
}

@media (max-width: 1600px) {
  .section1 .cont .left-t .link {
    margin-top: 51.42857px;
  }
}

@media (max-width: 1366px) {
  .section1 .cont .left-t .link {
    margin-top: 47.14286px;
  }
}

@media (max-width: 1289px) {
  .section1 .cont .left-t .link {
    margin-top: 42.85714px;
  }
}

@media (max-width: 1024px) {
  .section1 .cont .left-t .link {
    margin-top: 38.57143px;
  }
}

@media (max-width: 991px) {
  .section1 .cont .left-t .link {
    margin-top: 34.28571px;
  }
}

@media (max-width: 767px) {
  .section1 .cont .left-t .link {
    margin-top: 30px;
  }
}

@media screen and (max-width: 1366px) {
  .section1 .cont .left-t .link {
    margin-top: 30px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1699px) {
  .section1 .cont .left-t .link {
    margin-top: 28.57143px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1600px) {
  .section1 .cont .left-t .link {
    margin-top: 27.14286px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1366px) {
  .section1 .cont .left-t .link {
    margin-top: 25.71429px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1289px) {
  .section1 .cont .left-t .link {
    margin-top: 24.28571px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1024px) {
  .section1 .cont .left-t .link {
    margin-top: 22.85714px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 991px) {
  .section1 .cont .left-t .link {
    margin-top: 21.42857px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 767px) {
  .section1 .cont .left-t .link {
    margin-top: 20px;
  }
}

.section1 .cont .left-t .link a {
  flex-shrink: 0;
}

.section1 .cont .left-t .link a:first-child {
  margin-right: 25px;
}

@media (max-width: 1699px) {
  .section1 .cont .left-t .link a:first-child {
    margin-right: 23.57143px;
  }
}

@media (max-width: 1600px) {
  .section1 .cont .left-t .link a:first-child {
    margin-right: 22.14286px;
  }
}

@media (max-width: 1366px) {
  .section1 .cont .left-t .link a:first-child {
    margin-right: 20.71429px;
  }
}

@media (max-width: 1289px) {
  .section1 .cont .left-t .link a:first-child {
    margin-right: 19.28571px;
  }
}

@media (max-width: 1024px) {
  .section1 .cont .left-t .link a:first-child {
    margin-right: 17.85714px;
  }
}

@media (max-width: 991px) {
  .section1 .cont .left-t .link a:first-child {
    margin-right: 16.42857px;
  }
}

@media (max-width: 767px) {
  .section1 .cont .left-t .link a:first-child {
    margin-right: 15px;
  }
}

.section1 .cont .right-swiper {
  position: relative;
  float: right;
  width: 68%;
}

@media (max-width: 1024px) {
  .section1 .cont .right-swiper {
    float: none;
  }
}

@media (max-width: 1289px) {
  .section1 .cont .right-swiper {
    width: 65%;
  }
}

@media (max-width: 1024px) {
  .section1 .cont .right-swiper {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .section1 .cont .right-swiper {
    margin-top: 40px;
  }
}

@media screen and (max-width: 1024px) {
  .section1 .cont .right-swiper {
    padding-right: 50px;
  }
}

@media screen and (max-width: 1024px) and (max-width: 1699px) {
  .section1 .cont .right-swiper {
    padding-right: 47.14286px;
  }
}

@media screen and (max-width: 1024px) and (max-width: 1600px) {
  .section1 .cont .right-swiper {
    padding-right: 44.28571px;
  }
}

@media screen and (max-width: 1024px) and (max-width: 1366px) {
  .section1 .cont .right-swiper {
    padding-right: 41.42857px;
  }
}

@media screen and (max-width: 1024px) and (max-width: 1289px) {
  .section1 .cont .right-swiper {
    padding-right: 38.57143px;
  }
}

@media screen and (max-width: 1024px) and (max-width: 1024px) {
  .section1 .cont .right-swiper {
    padding-right: 35.71429px;
  }
}

@media screen and (max-width: 1024px) and (max-width: 991px) {
  .section1 .cont .right-swiper {
    padding-right: 32.85714px;
  }
}

@media screen and (max-width: 1024px) and (max-width: 767px) {
  .section1 .cont .right-swiper {
    padding-right: 30px;
  }
}

.section1 .cont .right-swiper .swiper {
  overflow: hidden;
  padding-left: 50px;
  padding-right: 50px;
}

@media (max-width: 1699px) {
  .section1 .cont .right-swiper .swiper {
    padding-left: 47.14286px;
  }
}

@media (max-width: 1600px) {
  .section1 .cont .right-swiper .swiper {
    padding-left: 44.28571px;
  }
}

@media (max-width: 1366px) {
  .section1 .cont .right-swiper .swiper {
    padding-left: 41.42857px;
  }
}

@media (max-width: 1289px) {
  .section1 .cont .right-swiper .swiper {
    padding-left: 38.57143px;
  }
}

@media (max-width: 1024px) {
  .section1 .cont .right-swiper .swiper {
    padding-left: 35.71429px;
  }
}

@media (max-width: 991px) {
  .section1 .cont .right-swiper .swiper {
    padding-left: 32.85714px;
  }
}

@media (max-width: 767px) {
  .section1 .cont .right-swiper .swiper {
    padding-left: 30px;
  }
}

@media (max-width: 1699px) {
  .section1 .cont .right-swiper .swiper {
    padding-right: 47.14286px;
  }
}

@media (max-width: 1600px) {
  .section1 .cont .right-swiper .swiper {
    padding-right: 44.28571px;
  }
}

@media (max-width: 1366px) {
  .section1 .cont .right-swiper .swiper {
    padding-right: 41.42857px;
  }
}

@media (max-width: 1289px) {
  .section1 .cont .right-swiper .swiper {
    padding-right: 38.57143px;
  }
}

@media (max-width: 1024px) {
  .section1 .cont .right-swiper .swiper {
    padding-right: 35.71429px;
  }
}

@media (max-width: 991px) {
  .section1 .cont .right-swiper .swiper {
    padding-right: 32.85714px;
  }
}

@media (max-width: 767px) {
  .section1 .cont .right-swiper .swiper {
    padding-right: 30px;
  }
}

@media screen and (max-width: 1024px) {
  .section1 .cont .right-swiper .swiper {
    padding-left: 0;
    padding-right: 0;
  }
}

.section1 .cont .right-swiper .swiper .swiper-slide {
  padding-top: 20px;
  padding-bottom: 20px;
}

.section1 .cont .right-swiper .swiper .swiper-slide .item {
  background-color: #fff;
  transition: box-shadow .3s,transform 1s,opacity 1s;
  transform: translateX(5%);
  opacity: 0;
  border-radius: 24px;
  padding-top: 60px;
  padding-bottom: 70px;
  padding-left: 40px;
  padding-right: 40px;
}

@media (max-width: 1024px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    transform: translateX(0);
  }
}

@media (max-width: 1024px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    opacity: 1;
  }
}

@media (max-width: 1699px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    border-radius: 23.42857px;
  }
}

@media (max-width: 1600px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    border-radius: 22.85714px;
  }
}

@media (max-width: 1366px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    border-radius: 22.28571px;
  }
}

@media (max-width: 1289px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    border-radius: 21.71429px;
  }
}

@media (max-width: 1024px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    border-radius: 21.14286px;
  }
}

@media (max-width: 991px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    border-radius: 20.57143px;
  }
}

@media (max-width: 767px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    border-radius: 20px;
  }
}

@media (max-width: 1699px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-top: 54.28571px;
  }
}

@media (max-width: 1600px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-top: 48.57143px;
  }
}

@media (max-width: 1366px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-top: 42.85714px;
  }
}

@media (max-width: 1289px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-top: 37.14286px;
  }
}

@media (max-width: 1024px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-top: 31.42857px;
  }
}

@media (max-width: 991px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-top: 25.71429px;
  }
}

@media (max-width: 767px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-top: 20px;
  }
}

@media (max-width: 1699px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-bottom: 62.85714px;
  }
}

@media (max-width: 1600px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-bottom: 55.71429px;
  }
}

@media (max-width: 1366px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-bottom: 48.57143px;
  }
}

@media (max-width: 1289px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-bottom: 41.42857px;
  }
}

@media (max-width: 1024px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-bottom: 34.28571px;
  }
}

@media (max-width: 991px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-bottom: 27.14286px;
  }
}

@media (max-width: 767px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-bottom: 20px;
  }
}

@media (max-width: 1699px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-left: 37.14286px;
  }
}

@media (max-width: 1600px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-left: 34.28571px;
  }
}

@media (max-width: 1366px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-left: 31.42857px;
  }
}

@media (max-width: 1289px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-left: 28.57143px;
  }
}

@media (max-width: 1024px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-left: 25.71429px;
  }
}

@media (max-width: 991px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-left: 22.85714px;
  }
}

@media (max-width: 767px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-left: 20px;
  }
}

@media (max-width: 1699px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-right: 37.14286px;
  }
}

@media (max-width: 1600px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-right: 34.28571px;
  }
}

@media (max-width: 1366px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-right: 31.42857px;
  }
}

@media (max-width: 1289px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-right: 28.57143px;
  }
}

@media (max-width: 1024px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-right: 25.71429px;
  }
}

@media (max-width: 991px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-right: 22.85714px;
  }
}

@media (max-width: 767px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-right: 20px;
  }
}

@media screen and (max-width: 1366px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1699px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-top: 37.14286px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1600px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-top: 34.28571px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1366px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-top: 31.42857px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1289px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-top: 28.57143px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1024px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-top: 25.71429px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 991px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-top: 22.85714px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 767px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-top: 20px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1699px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-bottom: 37.14286px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1600px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-bottom: 34.28571px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1366px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-bottom: 31.42857px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1289px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-bottom: 28.57143px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1024px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-bottom: 25.71429px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 991px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-bottom: 22.85714px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 767px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item {
    padding-bottom: 20px;
  }
}

.section1 .cont .right-swiper .swiper .swiper-slide .item:hover {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.section1 .cont .right-swiper .swiper .swiper-slide .item:hover .item-link {
  opacity: 1;
}

.section1 .cont .right-swiper .swiper .swiper-slide .item .item-img {
  margin-bottom: 80px;
}

@media (max-width: 1699px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-img {
    margin-bottom: 72.85714px;
  }
}

@media (max-width: 1600px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-img {
    margin-bottom: 65.71429px;
  }
}

@media (max-width: 1366px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-img {
    margin-bottom: 58.57143px;
  }
}

@media (max-width: 1289px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-img {
    margin-bottom: 51.42857px;
  }
}

@media (max-width: 1024px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-img {
    margin-bottom: 44.28571px;
  }
}

@media (max-width: 991px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-img {
    margin-bottom: 37.14286px;
  }
}

@media (max-width: 767px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-img {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 1366px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-img {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1699px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-img {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1600px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-img {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1366px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-img {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1289px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-img {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1024px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-img {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 991px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-img {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 767px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-img {
    margin-bottom: 20px;
  }
}

.section1 .cont .right-swiper .swiper .swiper-slide .item .item-img img {
  display: block;
  height: auto;
  width: 120px;
}

@media (max-width: 1699px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-img img {
    width: 117.14286px;
  }
}

@media (max-width: 1600px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-img img {
    width: 114.28571px;
  }
}

@media (max-width: 1366px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-img img {
    width: 111.42857px;
  }
}

@media (max-width: 1289px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-img img {
    width: 108.57143px;
  }
}

@media (max-width: 1024px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-img img {
    width: 105.71429px;
  }
}

@media (max-width: 991px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-img img {
    width: 102.85714px;
  }
}

@media (max-width: 767px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-img img {
    width: 100px;
  }
}

@media screen and (max-width: 1366px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-img img {
    width: 100px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1699px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-img img {
    width: 95.71429px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1600px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-img img {
    width: 91.42857px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1366px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-img img {
    width: 87.14286px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1289px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-img img {
    width: 82.85714px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1024px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-img img {
    width: 78.57143px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 991px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-img img {
    width: 74.28571px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 767px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-img img {
    width: 70px;
  }
}

.section1 .cont .right-swiper .swiper .swiper-slide .item .item-title {
  font-size: 16px;
  margin-bottom: 25px;
}

@media (max-width: 1699px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-title {
    margin-bottom: 23.57143px;
  }
}

@media (max-width: 1600px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-title {
    margin-bottom: 22.14286px;
  }
}

@media (max-width: 1366px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-title {
    margin-bottom: 20.71429px;
  }
}

@media (max-width: 1289px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-title {
    margin-bottom: 19.28571px;
  }
}

@media (max-width: 1024px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-title {
    margin-bottom: 17.85714px;
  }
}

@media (max-width: 991px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-title {
    margin-bottom: 16.42857px;
  }
}

@media (max-width: 767px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-title {
    margin-bottom: 15px;
  }
}

.section1 .cont .right-swiper .swiper .swiper-slide .item .item-desc {
  height: 50px;
}

@media (max-width: 767px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-desc {
    height: 100px;
  }
}

.section1 .cont .right-swiper .swiper .swiper-slide .item .item-link {
  transition: all .3s;
  opacity: 0;
  margin-top: 50px;
}

@media (max-width: 1024px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-link {
    opacity: 1;
  }
}

@media (max-width: 1699px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-link {
    margin-top: 45.71429px;
  }
}

@media (max-width: 1600px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-link {
    margin-top: 41.42857px;
  }
}

@media (max-width: 1366px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-link {
    margin-top: 37.14286px;
  }
}

@media (max-width: 1289px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-link {
    margin-top: 32.85714px;
  }
}

@media (max-width: 1024px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-link {
    margin-top: 28.57143px;
  }
}

@media (max-width: 991px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-link {
    margin-top: 24.28571px;
  }
}

@media (max-width: 767px) {
  .section1 .cont .right-swiper .swiper .swiper-slide .item .item-link {
    margin-top: 20px;
  }
}

.section1 .cont .right-swiper .swiper-button-next, .section1 .cont .right-swiper .swiper-button-prev {
  background-image: none;
  width: auto;
  height: auto;
  font-weight: lighter;
  top: auto;
  bottom: -10%;
}

@media screen and (max-width: 1366px) {
  .section1 .cont .right-swiper .swiper-button-next, .section1 .cont .right-swiper .swiper-button-prev {
    bottom: -8%;
  }
}

.section1 .cont .right-swiper .swiper-button-next::after, .section1 .cont .right-swiper .swiper-button-prev::after {
  font-family: 'iconfont';
  line-height: 1;
  color: #fff;
  transition: all .3s;
  font-size: 70px;
}

@media (max-width: 1699px) {
  .section1 .cont .right-swiper .swiper-button-next::after, .section1 .cont .right-swiper .swiper-button-prev::after {
    font-size: 67.14286px;
  }
}

@media (max-width: 1600px) {
  .section1 .cont .right-swiper .swiper-button-next::after, .section1 .cont .right-swiper .swiper-button-prev::after {
    font-size: 64.28571px;
  }
}

@media (max-width: 1366px) {
  .section1 .cont .right-swiper .swiper-button-next::after, .section1 .cont .right-swiper .swiper-button-prev::after {
    font-size: 61.42857px;
  }
}

@media (max-width: 1289px) {
  .section1 .cont .right-swiper .swiper-button-next::after, .section1 .cont .right-swiper .swiper-button-prev::after {
    font-size: 58.57143px;
  }
}

@media (max-width: 1024px) {
  .section1 .cont .right-swiper .swiper-button-next::after, .section1 .cont .right-swiper .swiper-button-prev::after {
    font-size: 55.71429px;
  }
}

@media (max-width: 991px) {
  .section1 .cont .right-swiper .swiper-button-next::after, .section1 .cont .right-swiper .swiper-button-prev::after {
    font-size: 52.85714px;
  }
}

@media (max-width: 767px) {
  .section1 .cont .right-swiper .swiper-button-next::after, .section1 .cont .right-swiper .swiper-button-prev::after {
    font-size: 50px;
  }
}

.section1 .cont .right-swiper .swiper-button-next:hover::after, .section1 .cont .right-swiper .swiper-button-prev:hover::after {
  color: #ff7800;
}

.section1 .cont .right-swiper .swiper-button-next {
  right: auto;
  left: 110px;
}

@media (max-width: 1699px) {
  .section1 .cont .right-swiper .swiper-button-next {
    left: 105.71429px;
  }
}

@media (max-width: 1600px) {
  .section1 .cont .right-swiper .swiper-button-next {
    left: 101.42857px;
  }
}

@media (max-width: 1366px) {
  .section1 .cont .right-swiper .swiper-button-next {
    left: 97.14286px;
  }
}

@media (max-width: 1289px) {
  .section1 .cont .right-swiper .swiper-button-next {
    left: 92.85714px;
  }
}

@media (max-width: 1024px) {
  .section1 .cont .right-swiper .swiper-button-next {
    left: 88.57143px;
  }
}

@media (max-width: 991px) {
  .section1 .cont .right-swiper .swiper-button-next {
    left: 84.28571px;
  }
}

@media (max-width: 767px) {
  .section1 .cont .right-swiper .swiper-button-next {
    left: 80px;
  }
}

@media screen and (max-width: 1024px) {
  .section1 .cont .right-swiper .swiper-button-next {
    left: 45%;
    right: auto;
  }
}

.section1 .cont .right-swiper .swiper-button-next::after {
  content: '\e60d';
}

.section1 .cont .right-swiper .swiper-button-prev {
  left: 40px;
}

@media (max-width: 1699px) {
  .section1 .cont .right-swiper .swiper-button-prev {
    left: 37.14286px;
  }
}

@media (max-width: 1600px) {
  .section1 .cont .right-swiper .swiper-button-prev {
    left: 34.28571px;
  }
}

@media (max-width: 1366px) {
  .section1 .cont .right-swiper .swiper-button-prev {
    left: 31.42857px;
  }
}

@media (max-width: 1289px) {
  .section1 .cont .right-swiper .swiper-button-prev {
    left: 28.57143px;
  }
}

@media (max-width: 1024px) {
  .section1 .cont .right-swiper .swiper-button-prev {
    left: 25.71429px;
  }
}

@media (max-width: 991px) {
  .section1 .cont .right-swiper .swiper-button-prev {
    left: 22.85714px;
  }
}

@media (max-width: 767px) {
  .section1 .cont .right-swiper .swiper-button-prev {
    left: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .section1 .cont .right-swiper .swiper-button-prev {
    right: 55%;
    left: auto;
  }
}

.section1 .cont .right-swiper .swiper-button-prev::after {
  content: '\eb90';
}

.section1.active .cont .right-swiper .swiper .swiper-slide .item {
  opacity: 1;
  transform: translateX(0);
}

.section1.active .cont .right-swiper .swiper .swiper-slide:nth-child(1) .item {
  transition-delay: .2s;
}

.section1.active .cont .right-swiper .swiper .swiper-slide:nth-child(2) .item {
  transition-delay: .4s;
}

.section2 {
  background-color: #000;
  position: relative;
}

.section2 .bg-swiper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: .5;
  transform-origin: center;
  transition: transform .8s;
  transform: scale(0.9);
}

@media (max-width: 1024px) {
  .section2 .bg-swiper {
    transform: scale(1);
  }
}

.section2 .bg-swiper .swiper-container {
  height: 100%;
}

.section2 .bg-swiper .swiper-container .swiper-slide b {
  display: block;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  transform: scale(0.7);
  transform-origin: center;
  transition: transform 1.5s;
}

.section2 .bg-swiper .swiper-container .swiper-slide.swiper-slide-active b {
  transform: scale(1);
}

.section2 .cont {
  position: relative;
  z-index: 1;
  color: #fff;
  margin-bottom: 100px;
}

@media (max-width: 1289px) {
  .section2 .cont {
    margin-bottom: 20px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 1366px) {
  .section2 .cont {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1024px) {
  .section2 .cont {
    margin-bottom: 0;
  }
}

.section2 .cont .top-text {
  margin-bottom: 125px;
}

@media (max-width: 1699px) {
  .section2 .cont .top-text {
    margin-bottom: 114.28571px;
  }
}

@media (max-width: 1600px) {
  .section2 .cont .top-text {
    margin-bottom: 103.57143px;
  }
}

@media (max-width: 1366px) {
  .section2 .cont .top-text {
    margin-bottom: 92.85714px;
  }
}

@media (max-width: 1289px) {
  .section2 .cont .top-text {
    margin-bottom: 82.14286px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .top-text {
    margin-bottom: 71.42857px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .top-text {
    margin-bottom: 60.71429px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .top-text {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 1366px) {
  .section2 .cont .top-text {
    margin-bottom: 70px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1699px) {
  .section2 .cont .top-text {
    margin-bottom: 64.28571px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1600px) {
  .section2 .cont .top-text {
    margin-bottom: 58.57143px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1366px) {
  .section2 .cont .top-text {
    margin-bottom: 52.85714px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1289px) {
  .section2 .cont .top-text {
    margin-bottom: 47.14286px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1024px) {
  .section2 .cont .top-text {
    margin-bottom: 41.42857px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 991px) {
  .section2 .cont .top-text {
    margin-bottom: 35.71429px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 767px) {
  .section2 .cont .top-text {
    margin-bottom: 30px;
  }
}

.section2 .cont .top-text .left {
  float: left;
}

.section2 .cont .top-text .right {
  float: right;
}

.section2 .cont .top-text .right a {
  display: inline-block;
  border-radius: 50px;
  color: #fff;
  transition: all .3s;
  border: 1px solid #fff;
  font-size: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 15px;
}

@media (max-width: 1699px) {
  .section2 .cont .top-text .right a {
    font-size: 15.71429px;
  }
}

@media (max-width: 1600px) {
  .section2 .cont .top-text .right a {
    font-size: 15.42857px;
  }
}

@media (max-width: 1366px) {
  .section2 .cont .top-text .right a {
    font-size: 15.14286px;
  }
}

@media (max-width: 1289px) {
  .section2 .cont .top-text .right a {
    font-size: 14.85714px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .top-text .right a {
    font-size: 14.57143px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .top-text .right a {
    font-size: 14.28571px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .top-text .right a {
    font-size: 14px;
  }
}

@media (max-width: 1699px) {
  .section2 .cont .top-text .right a {
    padding-top: 5px;
  }
}

@media (max-width: 1600px) {
  .section2 .cont .top-text .right a {
    padding-top: 5px;
  }
}

@media (max-width: 1366px) {
  .section2 .cont .top-text .right a {
    padding-top: 5px;
  }
}

@media (max-width: 1289px) {
  .section2 .cont .top-text .right a {
    padding-top: 5px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .top-text .right a {
    padding-top: 5px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .top-text .right a {
    padding-top: 5px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .top-text .right a {
    padding-top: 5px;
  }
}

@media (max-width: 1699px) {
  .section2 .cont .top-text .right a {
    padding-bottom: 5px;
  }
}

@media (max-width: 1600px) {
  .section2 .cont .top-text .right a {
    padding-bottom: 5px;
  }
}

@media (max-width: 1366px) {
  .section2 .cont .top-text .right a {
    padding-bottom: 5px;
  }
}

@media (max-width: 1289px) {
  .section2 .cont .top-text .right a {
    padding-bottom: 5px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .top-text .right a {
    padding-bottom: 5px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .top-text .right a {
    padding-bottom: 5px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .top-text .right a {
    padding-bottom: 5px;
  }
}

@media (max-width: 1699px) {
  .section2 .cont .top-text .right a {
    padding-left: 46.42857px;
  }
}

@media (max-width: 1600px) {
  .section2 .cont .top-text .right a {
    padding-left: 42.85714px;
  }
}

@media (max-width: 1366px) {
  .section2 .cont .top-text .right a {
    padding-left: 39.28571px;
  }
}

@media (max-width: 1289px) {
  .section2 .cont .top-text .right a {
    padding-left: 35.71429px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .top-text .right a {
    padding-left: 32.14286px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .top-text .right a {
    padding-left: 28.57143px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .top-text .right a {
    padding-left: 25px;
  }
}

@media (max-width: 1699px) {
  .section2 .cont .top-text .right a {
    padding-right: 46.42857px;
  }
}

@media (max-width: 1600px) {
  .section2 .cont .top-text .right a {
    padding-right: 42.85714px;
  }
}

@media (max-width: 1366px) {
  .section2 .cont .top-text .right a {
    padding-right: 39.28571px;
  }
}

@media (max-width: 1289px) {
  .section2 .cont .top-text .right a {
    padding-right: 35.71429px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .top-text .right a {
    padding-right: 32.14286px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .top-text .right a {
    padding-right: 28.57143px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .top-text .right a {
    padding-right: 25px;
  }
}

.section2 .cont .top-text .right a:hover {
  border: 1px solid #ff7800;
  background-color: #ff7800;
}

@media (max-width: 1699px) {
  .section2 .cont .top-text .right a {
    margin-top: 14.28571px;
  }
}

@media (max-width: 1600px) {
  .section2 .cont .top-text .right a {
    margin-top: 13.57143px;
  }
}

@media (max-width: 1366px) {
  .section2 .cont .top-text .right a {
    margin-top: 12.85714px;
  }
}

@media (max-width: 1289px) {
  .section2 .cont .top-text .right a {
    margin-top: 12.14286px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .top-text .right a {
    margin-top: 11.42857px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .top-text .right a {
    margin-top: 10.71429px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .top-text .right a {
    margin-top: 10px;
  }
}

.section2 .cont .swiper-cont .left {
  float: left;
  width: 330px;
}

@media (max-width: 991px) {
  .section2 .cont .swiper-cont .left {
    float: none;
  }
}

@media (max-width: 991px) {
  .section2 .cont .swiper-cont .left {
    width: 100%;
  }
}

.section2 .cont .swiper-cont .left .swiper .swiper-slide > div {
  opacity: 0;
  transition: transform 1s, opacity 1s;
  transform: translateY(20%);
}

.section2 .cont .swiper-cont .left .swiper .swiper-slide.swiper-slide-active > div {
  opacity: 1;
  transform: translateY(0);
}

.section2 .cont .swiper-cont .left .swiper .swiper-slide .title {
  font-size: 20px;
  margin-bottom: 10px;
}

@media (max-width: 1699px) {
  .section2 .cont .swiper-cont .left .swiper .swiper-slide .title {
    font-size: 19.71429px;
  }
}

@media (max-width: 1600px) {
  .section2 .cont .swiper-cont .left .swiper .swiper-slide .title {
    font-size: 19.42857px;
  }
}

@media (max-width: 1366px) {
  .section2 .cont .swiper-cont .left .swiper .swiper-slide .title {
    font-size: 19.14286px;
  }
}

@media (max-width: 1289px) {
  .section2 .cont .swiper-cont .left .swiper .swiper-slide .title {
    font-size: 18.85714px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .swiper-cont .left .swiper .swiper-slide .title {
    font-size: 18.57143px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .swiper-cont .left .swiper .swiper-slide .title {
    font-size: 18.28571px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .swiper-cont .left .swiper .swiper-slide .title {
    font-size: 18px;
  }
}

.section2 .cont .swiper-cont .left .swiper .swiper-slide .desc {
  font-size: 14px;
  margin-bottom: 25px;
}

@media (max-width: 1699px) {
  .section2 .cont .swiper-cont .left .swiper .swiper-slide .desc {
    margin-bottom: 23.57143px;
  }
}

@media (max-width: 1600px) {
  .section2 .cont .swiper-cont .left .swiper .swiper-slide .desc {
    margin-bottom: 22.14286px;
  }
}

@media (max-width: 1366px) {
  .section2 .cont .swiper-cont .left .swiper .swiper-slide .desc {
    margin-bottom: 20.71429px;
  }
}

@media (max-width: 1289px) {
  .section2 .cont .swiper-cont .left .swiper .swiper-slide .desc {
    margin-bottom: 19.28571px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .swiper-cont .left .swiper .swiper-slide .desc {
    margin-bottom: 17.85714px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .swiper-cont .left .swiper .swiper-slide .desc {
    margin-bottom: 16.42857px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .swiper-cont .left .swiper .swiper-slide .desc {
    margin-bottom: 15px;
  }
}

.section2 .cont .swiper-cont .left .swiper .swiper-slide .link {
  font-size: 14px;
  margin-bottom: 130px;
}

@media (max-width: 1699px) {
  .section2 .cont .swiper-cont .left .swiper .swiper-slide .link {
    margin-bottom: 118.57143px;
  }
}

@media (max-width: 1600px) {
  .section2 .cont .swiper-cont .left .swiper .swiper-slide .link {
    margin-bottom: 107.14286px;
  }
}

@media (max-width: 1366px) {
  .section2 .cont .swiper-cont .left .swiper .swiper-slide .link {
    margin-bottom: 95.71429px;
  }
}

@media (max-width: 1289px) {
  .section2 .cont .swiper-cont .left .swiper .swiper-slide .link {
    margin-bottom: 84.28571px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .swiper-cont .left .swiper .swiper-slide .link {
    margin-bottom: 72.85714px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .swiper-cont .left .swiper .swiper-slide .link {
    margin-bottom: 61.42857px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .swiper-cont .left .swiper .swiper-slide .link {
    margin-bottom: 50px;
  }
}

.section2 .cont .swiper-cont .left .swiper .swiper-slide .link > div {
  margin-bottom: 10px;
}

.section2 .cont .swiper-cont .left .swiper .swiper-slide .link a {
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50px;
  transition: all .3s;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.section2 .cont .swiper-cont .left .swiper .swiper-slide .link a .iconfont {
  font-size: 14px;
  color: #ff7800;
  transition: all .3s;
  display: inline-block;
  margin-left: 25px;
}

.section2 .cont .swiper-cont .left .swiper .swiper-slide .link a:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

.section2 .cont .swiper-cont .left .swiper .swiper-slide .link a:hover .iconfont {
  transform: translateX(5px);
}

.section2 .cont .swiper-cont .left .swiper-button {
  align-items: center;
  justify-content: center;
  display: none;
  margin-top: 100px;
}

@media (max-width: 991px) {
  .section2 .cont .swiper-cont .left .swiper-button {
    display: flex;
  }
}

@media (max-width: 1699px) {
  .section2 .cont .swiper-cont .left .swiper-button {
    margin-top: 92.85714px;
  }
}

@media (max-width: 1600px) {
  .section2 .cont .swiper-cont .left .swiper-button {
    margin-top: 85.71429px;
  }
}

@media (max-width: 1366px) {
  .section2 .cont .swiper-cont .left .swiper-button {
    margin-top: 78.57143px;
  }
}

@media (max-width: 1289px) {
  .section2 .cont .swiper-cont .left .swiper-button {
    margin-top: 71.42857px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .swiper-cont .left .swiper-button {
    margin-top: 64.28571px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .swiper-cont .left .swiper-button {
    margin-top: 57.14286px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .swiper-cont .left .swiper-button {
    margin-top: 50px;
  }
}

.section2 .cont .swiper-cont .left .swiper-button .swiper-button-prev, .section2 .cont .swiper-cont .left .swiper-button .swiper-button-next {
  position: static;
  background-image: none;
  width: auto;
  height: auto;
}

.section2 .cont .swiper-cont .left .swiper-button .swiper-button-prev::after, .section2 .cont .swiper-cont .left .swiper-button .swiper-button-next::after {
  font-family: 'iconfont';
  line-height: 1;
  color: #fff;
  transition: all .3s;
  font-size: 70px;
}

@media (max-width: 1699px) {
  .section2 .cont .swiper-cont .left .swiper-button .swiper-button-prev::after, .section2 .cont .swiper-cont .left .swiper-button .swiper-button-next::after {
    font-size: 67.14286px;
  }
}

@media (max-width: 1600px) {
  .section2 .cont .swiper-cont .left .swiper-button .swiper-button-prev::after, .section2 .cont .swiper-cont .left .swiper-button .swiper-button-next::after {
    font-size: 64.28571px;
  }
}

@media (max-width: 1366px) {
  .section2 .cont .swiper-cont .left .swiper-button .swiper-button-prev::after, .section2 .cont .swiper-cont .left .swiper-button .swiper-button-next::after {
    font-size: 61.42857px;
  }
}

@media (max-width: 1289px) {
  .section2 .cont .swiper-cont .left .swiper-button .swiper-button-prev::after, .section2 .cont .swiper-cont .left .swiper-button .swiper-button-next::after {
    font-size: 58.57143px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .swiper-cont .left .swiper-button .swiper-button-prev::after, .section2 .cont .swiper-cont .left .swiper-button .swiper-button-next::after {
    font-size: 55.71429px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .swiper-cont .left .swiper-button .swiper-button-prev::after, .section2 .cont .swiper-cont .left .swiper-button .swiper-button-next::after {
    font-size: 52.85714px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .swiper-cont .left .swiper-button .swiper-button-prev::after, .section2 .cont .swiper-cont .left .swiper-button .swiper-button-next::after {
    font-size: 50px;
  }
}

.section2 .cont .swiper-cont .left .swiper-button .swiper-button-prev:hover::after, .section2 .cont .swiper-cont .left .swiper-button .swiper-button-next:hover::after {
  color: #ff7800;
}

.section2 .cont .swiper-cont .left .swiper-button .swiper-button-next::after {
  content: '\e60d';
}

.section2 .cont .swiper-cont .left .swiper-button .swiper-button-prev::after {
  content: '\eb90';
}

.section2 .cont .swiper-cont .right {
  overflow: hidden;
  border-radius: 25px;
  position: relative;
  background-color: #fff;
  color: #3d3a39;
  transition: all 1s;
  opacity: 0;
  transform: translateY(10%);
  width: 400px;
  float: right;
  display: block;
}

@media (max-width: 1024px) {
  .section2 .cont .swiper-cont .right {
    opacity: 1;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .swiper-cont .right {
    transform: translateY(0%);
  }
}

@media (max-width: 1366px) {
  .section2 .cont .swiper-cont .right {
    width: 350px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .swiper-cont .right {
    width: 300px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .swiper-cont .right {
    display: none;
  }
}

.section2 .cont .swiper-cont .right > div {
  position: relative;
  z-index: 1;
}

.section2 .cont .swiper-cont .right .item {
  cursor: pointer;
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (max-width: 1699px) {
  .section2 .cont .swiper-cont .right .item {
    padding-top: 45.71429px;
  }
}

@media (max-width: 1600px) {
  .section2 .cont .swiper-cont .right .item {
    padding-top: 41.42857px;
  }
}

@media (max-width: 1366px) {
  .section2 .cont .swiper-cont .right .item {
    padding-top: 37.14286px;
  }
}

@media (max-width: 1289px) {
  .section2 .cont .swiper-cont .right .item {
    padding-top: 32.85714px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .swiper-cont .right .item {
    padding-top: 28.57143px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .swiper-cont .right .item {
    padding-top: 24.28571px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .swiper-cont .right .item {
    padding-top: 20px;
  }
}

@media (max-width: 1699px) {
  .section2 .cont .swiper-cont .right .item {
    padding-bottom: 45.71429px;
  }
}

@media (max-width: 1600px) {
  .section2 .cont .swiper-cont .right .item {
    padding-bottom: 41.42857px;
  }
}

@media (max-width: 1366px) {
  .section2 .cont .swiper-cont .right .item {
    padding-bottom: 37.14286px;
  }
}

@media (max-width: 1289px) {
  .section2 .cont .swiper-cont .right .item {
    padding-bottom: 32.85714px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .swiper-cont .right .item {
    padding-bottom: 28.57143px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .swiper-cont .right .item {
    padding-bottom: 24.28571px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .swiper-cont .right .item {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 1366px) {
  .section2 .cont .swiper-cont .right .item {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1699px) {
  .section2 .cont .swiper-cont .right .item {
    padding-top: 28.57143px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1600px) {
  .section2 .cont .swiper-cont .right .item {
    padding-top: 27.14286px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1366px) {
  .section2 .cont .swiper-cont .right .item {
    padding-top: 25.71429px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1289px) {
  .section2 .cont .swiper-cont .right .item {
    padding-top: 24.28571px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1024px) {
  .section2 .cont .swiper-cont .right .item {
    padding-top: 22.85714px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 991px) {
  .section2 .cont .swiper-cont .right .item {
    padding-top: 21.42857px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 767px) {
  .section2 .cont .swiper-cont .right .item {
    padding-top: 20px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1699px) {
  .section2 .cont .swiper-cont .right .item {
    padding-bottom: 28.57143px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1600px) {
  .section2 .cont .swiper-cont .right .item {
    padding-bottom: 27.14286px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1366px) {
  .section2 .cont .swiper-cont .right .item {
    padding-bottom: 25.71429px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1289px) {
  .section2 .cont .swiper-cont .right .item {
    padding-bottom: 24.28571px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1024px) {
  .section2 .cont .swiper-cont .right .item {
    padding-bottom: 22.85714px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 991px) {
  .section2 .cont .swiper-cont .right .item {
    padding-bottom: 21.42857px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 767px) {
  .section2 .cont .swiper-cont .right .item {
    padding-bottom: 20px;
  }
}

.section2 .cont .swiper-cont .right .item.active img {
  filter: brightness(100%);
}

.section2 .cont .swiper-cont .right .item.active p {
  color: #fff;
}

.section2 .cont .swiper-cont .right .item img {
  float: left;
  width: 45px;
  height: 45px;
  filter: brightness(30%);
  transition: all .5s;
  margin-left: 80px;
  margin-right: 30px;
}

@media (max-width: 1699px) {
  .section2 .cont .swiper-cont .right .item img {
    margin-left: 71.42857px;
  }
}

@media (max-width: 1600px) {
  .section2 .cont .swiper-cont .right .item img {
    margin-left: 62.85714px;
  }
}

@media (max-width: 1366px) {
  .section2 .cont .swiper-cont .right .item img {
    margin-left: 54.28571px;
  }
}

@media (max-width: 1289px) {
  .section2 .cont .swiper-cont .right .item img {
    margin-left: 45.71429px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .swiper-cont .right .item img {
    margin-left: 37.14286px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .swiper-cont .right .item img {
    margin-left: 28.57143px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .swiper-cont .right .item img {
    margin-left: 20px;
  }
}

@media (max-width: 1699px) {
  .section2 .cont .swiper-cont .right .item img {
    margin-right: 28.57143px;
  }
}

@media (max-width: 1600px) {
  .section2 .cont .swiper-cont .right .item img {
    margin-right: 27.14286px;
  }
}

@media (max-width: 1366px) {
  .section2 .cont .swiper-cont .right .item img {
    margin-right: 25.71429px;
  }
}

@media (max-width: 1289px) {
  .section2 .cont .swiper-cont .right .item img {
    margin-right: 24.28571px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .swiper-cont .right .item img {
    margin-right: 22.85714px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .swiper-cont .right .item img {
    margin-right: 21.42857px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .swiper-cont .right .item img {
    margin-right: 20px;
  }
}

.section2 .cont .swiper-cont .right .item p {
  line-height: 45px;
  white-space: nowrap;
  float: left;
  transition: all .5s;
  font-size: 20px;
}

@media (max-width: 1699px) {
  .section2 .cont .swiper-cont .right .item p {
    font-size: 19.71429px;
  }
}

@media (max-width: 1600px) {
  .section2 .cont .swiper-cont .right .item p {
    font-size: 19.42857px;
  }
}

@media (max-width: 1366px) {
  .section2 .cont .swiper-cont .right .item p {
    font-size: 19.14286px;
  }
}

@media (max-width: 1289px) {
  .section2 .cont .swiper-cont .right .item p {
    font-size: 18.85714px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .swiper-cont .right .item p {
    font-size: 18.57143px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .swiper-cont .right .item p {
    font-size: 18.28571px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .swiper-cont .right .item p {
    font-size: 18px;
  }
}

.section2 .cont .swiper-cont .right .bg-trank {
  position: absolute;
  z-index: 0;
  top: 0;
  width: 100%;
  background-color: #ff7800;
  height: 33.33%;
  transition: all .5s;
}

.section2.active .bg-swiper {
  transform: scale(1);
  transition-delay: .1s;
}

.section2.active .cont .right {
  opacity: 1;
  transform: translateY(0);
  transition-delay: .2s;
}

.section3 {
  position: relative;
}

.section3::after {
  content: '';
  width: 35%;
  height: 100%;
  background-color: #edecec;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  display: block;
}

@media (max-width: 991px) {
  .section3::after {
    display: none;
  }
}

.section3 .bg-canvas {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 60%;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.section3 .bg-canvas canvas {
  width: 100%;
  height: 100%;
  pointer-events: none;
  touch-callout: none;
  transform: translateY(40%);
}

.section3 .cont {
  position: relative;
  z-index: 2;
}

.section3 .cont .left {
  width: 40%;
}

@media (max-width: 991px) {
  .section3 .cont .left {
    width: 100%;
  }
}

.section3 .cont .left .top-text .more-btn a {
  display: inline-block;
  border-radius: 50px;
  color: #ff7800;
  transition: all .3s;
  border: 1px solid #ff7800;
  font-size: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 15px;
}

@media (max-width: 1699px) {
  .section3 .cont .left .top-text .more-btn a {
    font-size: 15.71429px;
  }
}

@media (max-width: 1600px) {
  .section3 .cont .left .top-text .more-btn a {
    font-size: 15.42857px;
  }
}

@media (max-width: 1366px) {
  .section3 .cont .left .top-text .more-btn a {
    font-size: 15.14286px;
  }
}

@media (max-width: 1289px) {
  .section3 .cont .left .top-text .more-btn a {
    font-size: 14.85714px;
  }
}

@media (max-width: 1024px) {
  .section3 .cont .left .top-text .more-btn a {
    font-size: 14.57143px;
  }
}

@media (max-width: 991px) {
  .section3 .cont .left .top-text .more-btn a {
    font-size: 14.28571px;
  }
}

@media (max-width: 767px) {
  .section3 .cont .left .top-text .more-btn a {
    font-size: 14px;
  }
}

@media (max-width: 1699px) {
  .section3 .cont .left .top-text .more-btn a {
    padding-top: 5px;
  }
}

@media (max-width: 1600px) {
  .section3 .cont .left .top-text .more-btn a {
    padding-top: 5px;
  }
}

@media (max-width: 1366px) {
  .section3 .cont .left .top-text .more-btn a {
    padding-top: 5px;
  }
}

@media (max-width: 1289px) {
  .section3 .cont .left .top-text .more-btn a {
    padding-top: 5px;
  }
}

@media (max-width: 1024px) {
  .section3 .cont .left .top-text .more-btn a {
    padding-top: 5px;
  }
}

@media (max-width: 991px) {
  .section3 .cont .left .top-text .more-btn a {
    padding-top: 5px;
  }
}

@media (max-width: 767px) {
  .section3 .cont .left .top-text .more-btn a {
    padding-top: 5px;
  }
}

@media (max-width: 1699px) {
  .section3 .cont .left .top-text .more-btn a {
    padding-bottom: 5px;
  }
}

@media (max-width: 1600px) {
  .section3 .cont .left .top-text .more-btn a {
    padding-bottom: 5px;
  }
}

@media (max-width: 1366px) {
  .section3 .cont .left .top-text .more-btn a {
    padding-bottom: 5px;
  }
}

@media (max-width: 1289px) {
  .section3 .cont .left .top-text .more-btn a {
    padding-bottom: 5px;
  }
}

@media (max-width: 1024px) {
  .section3 .cont .left .top-text .more-btn a {
    padding-bottom: 5px;
  }
}

@media (max-width: 991px) {
  .section3 .cont .left .top-text .more-btn a {
    padding-bottom: 5px;
  }
}

@media (max-width: 767px) {
  .section3 .cont .left .top-text .more-btn a {
    padding-bottom: 5px;
  }
}

@media (max-width: 1699px) {
  .section3 .cont .left .top-text .more-btn a {
    padding-left: 46.42857px;
  }
}

@media (max-width: 1600px) {
  .section3 .cont .left .top-text .more-btn a {
    padding-left: 42.85714px;
  }
}

@media (max-width: 1366px) {
  .section3 .cont .left .top-text .more-btn a {
    padding-left: 39.28571px;
  }
}

@media (max-width: 1289px) {
  .section3 .cont .left .top-text .more-btn a {
    padding-left: 35.71429px;
  }
}

@media (max-width: 1024px) {
  .section3 .cont .left .top-text .more-btn a {
    padding-left: 32.14286px;
  }
}

@media (max-width: 991px) {
  .section3 .cont .left .top-text .more-btn a {
    padding-left: 28.57143px;
  }
}

@media (max-width: 767px) {
  .section3 .cont .left .top-text .more-btn a {
    padding-left: 25px;
  }
}

@media (max-width: 1699px) {
  .section3 .cont .left .top-text .more-btn a {
    padding-right: 46.42857px;
  }
}

@media (max-width: 1600px) {
  .section3 .cont .left .top-text .more-btn a {
    padding-right: 42.85714px;
  }
}

@media (max-width: 1366px) {
  .section3 .cont .left .top-text .more-btn a {
    padding-right: 39.28571px;
  }
}

@media (max-width: 1289px) {
  .section3 .cont .left .top-text .more-btn a {
    padding-right: 35.71429px;
  }
}

@media (max-width: 1024px) {
  .section3 .cont .left .top-text .more-btn a {
    padding-right: 32.14286px;
  }
}

@media (max-width: 991px) {
  .section3 .cont .left .top-text .more-btn a {
    padding-right: 28.57143px;
  }
}

@media (max-width: 767px) {
  .section3 .cont .left .top-text .more-btn a {
    padding-right: 25px;
  }
}

.section3 .cont .left .top-text .more-btn a:hover {
  color: #fff;
  background-color: #ff7800;
}

@media (max-width: 1699px) {
  .section3 .cont .left .top-text .more-btn a {
    margin-top: 14.28571px;
  }
}

@media (max-width: 1600px) {
  .section3 .cont .left .top-text .more-btn a {
    margin-top: 13.57143px;
  }
}

@media (max-width: 1366px) {
  .section3 .cont .left .top-text .more-btn a {
    margin-top: 12.85714px;
  }
}

@media (max-width: 1289px) {
  .section3 .cont .left .top-text .more-btn a {
    margin-top: 12.14286px;
  }
}

@media (max-width: 1024px) {
  .section3 .cont .left .top-text .more-btn a {
    margin-top: 11.42857px;
  }
}

@media (max-width: 991px) {
  .section3 .cont .left .top-text .more-btn a {
    margin-top: 10.71429px;
  }
}

@media (max-width: 767px) {
  .section3 .cont .left .top-text .more-btn a {
    margin-top: 10px;
  }
}

.section3 .cont .left .num {
  margin-top: 100px;
}

@media (max-width: 1699px) {
  .section3 .cont .left .num {
    margin-top: 90px;
  }
}

@media (max-width: 1600px) {
  .section3 .cont .left .num {
    margin-top: 80px;
  }
}

@media (max-width: 1366px) {
  .section3 .cont .left .num {
    margin-top: 70px;
  }
}

@media (max-width: 1289px) {
  .section3 .cont .left .num {
    margin-top: 60px;
  }
}

@media (max-width: 1024px) {
  .section3 .cont .left .num {
    margin-top: 50px;
  }
}

@media (max-width: 991px) {
  .section3 .cont .left .num {
    margin-top: 40px;
  }
}

@media (max-width: 767px) {
  .section3 .cont .left .num {
    margin-top: 30px;
  }
}

@media screen and (max-width: 1366px) {
  .section3 .cont .left .num {
    margin-top: 50px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1699px) {
  .section3 .cont .left .num {
    margin-top: 47.14286px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1600px) {
  .section3 .cont .left .num {
    margin-top: 44.28571px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1366px) {
  .section3 .cont .left .num {
    margin-top: 41.42857px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1289px) {
  .section3 .cont .left .num {
    margin-top: 38.57143px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1024px) {
  .section3 .cont .left .num {
    margin-top: 35.71429px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 991px) {
  .section3 .cont .left .num {
    margin-top: 32.85714px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 767px) {
  .section3 .cont .left .num {
    margin-top: 30px;
  }
}

.section3 .cont .left .num .item {
  float: left;
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  background-color: #fff;
  text-align: center;
  font-size: 20px;
  margin-top: 20px;
  width: 45%;
  margin-right: 5%;
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (max-width: 1699px) {
  .section3 .cont .left .num .item {
    font-size: 19.42857px;
  }
}

@media (max-width: 1600px) {
  .section3 .cont .left .num .item {
    font-size: 18.85714px;
  }
}

@media (max-width: 1366px) {
  .section3 .cont .left .num .item {
    font-size: 18.28571px;
  }
}

@media (max-width: 1289px) {
  .section3 .cont .left .num .item {
    font-size: 17.71429px;
  }
}

@media (max-width: 1024px) {
  .section3 .cont .left .num .item {
    font-size: 17.14286px;
  }
}

@media (max-width: 991px) {
  .section3 .cont .left .num .item {
    font-size: 16.57143px;
  }
}

@media (max-width: 767px) {
  .section3 .cont .left .num .item {
    font-size: 16px;
  }
}

@media (max-width: 1699px) {
  .section3 .cont .left .num .item {
    margin-top: 18.57143px;
  }
}

@media (max-width: 1600px) {
  .section3 .cont .left .num .item {
    margin-top: 17.14286px;
  }
}

@media (max-width: 1366px) {
  .section3 .cont .left .num .item {
    margin-top: 15.71429px;
  }
}

@media (max-width: 1289px) {
  .section3 .cont .left .num .item {
    margin-top: 14.28571px;
  }
}

@media (max-width: 1024px) {
  .section3 .cont .left .num .item {
    margin-top: 12.85714px;
  }
}

@media (max-width: 991px) {
  .section3 .cont .left .num .item {
    margin-top: 11.42857px;
  }
}

@media (max-width: 767px) {
  .section3 .cont .left .num .item {
    margin-top: 10px;
  }
}

@media (max-width: 991px) {
  .section3 .cont .left .num .item {
    width: 23%;
  }
}

@media (max-width: 767px) {
  .section3 .cont .left .num .item {
    width: 45%;
  }
}

@media (max-width: 991px) {
  .section3 .cont .left .num .item {
    margin-right: 2%;
  }
}

@media (max-width: 767px) {
  .section3 .cont .left .num .item {
    margin-right: 5%;
  }
}

@media (max-width: 1699px) {
  .section3 .cont .left .num .item {
    padding-top: 28.57143px;
  }
}

@media (max-width: 1600px) {
  .section3 .cont .left .num .item {
    padding-top: 27.14286px;
  }
}

@media (max-width: 1366px) {
  .section3 .cont .left .num .item {
    padding-top: 25.71429px;
  }
}

@media (max-width: 1289px) {
  .section3 .cont .left .num .item {
    padding-top: 24.28571px;
  }
}

@media (max-width: 1024px) {
  .section3 .cont .left .num .item {
    padding-top: 22.85714px;
  }
}

@media (max-width: 991px) {
  .section3 .cont .left .num .item {
    padding-top: 21.42857px;
  }
}

@media (max-width: 767px) {
  .section3 .cont .left .num .item {
    padding-top: 20px;
  }
}

@media (max-width: 1699px) {
  .section3 .cont .left .num .item {
    padding-bottom: 28.57143px;
  }
}

@media (max-width: 1600px) {
  .section3 .cont .left .num .item {
    padding-bottom: 27.14286px;
  }
}

@media (max-width: 1366px) {
  .section3 .cont .left .num .item {
    padding-bottom: 25.71429px;
  }
}

@media (max-width: 1289px) {
  .section3 .cont .left .num .item {
    padding-bottom: 24.28571px;
  }
}

@media (max-width: 1024px) {
  .section3 .cont .left .num .item {
    padding-bottom: 22.85714px;
  }
}

@media (max-width: 991px) {
  .section3 .cont .left .num .item {
    padding-bottom: 21.42857px;
  }
}

@media (max-width: 767px) {
  .section3 .cont .left .num .item {
    padding-bottom: 20px;
  }
}

.section3 .cont .left .num .item > div {
  color: #ff7800;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 15px;
}

@media (max-width: 1699px) {
  .section3 .cont .left .num .item > div {
    margin-bottom: 14.28571px;
  }
}

@media (max-width: 1600px) {
  .section3 .cont .left .num .item > div {
    margin-bottom: 13.57143px;
  }
}

@media (max-width: 1366px) {
  .section3 .cont .left .num .item > div {
    margin-bottom: 12.85714px;
  }
}

@media (max-width: 1289px) {
  .section3 .cont .left .num .item > div {
    margin-bottom: 12.14286px;
  }
}

@media (max-width: 1024px) {
  .section3 .cont .left .num .item > div {
    margin-bottom: 11.42857px;
  }
}

@media (max-width: 991px) {
  .section3 .cont .left .num .item > div {
    margin-bottom: 10.71429px;
  }
}

@media (max-width: 767px) {
  .section3 .cont .left .num .item > div {
    margin-bottom: 10px;
  }
}

.section3 .cont .left .num .item > div span {
  font-size: 90px;
}

@media (max-width: 1699px) {
  .section3 .cont .left .num .item > div span {
    font-size: 84.28571px;
  }
}

@media (max-width: 1600px) {
  .section3 .cont .left .num .item > div span {
    font-size: 78.57143px;
  }
}

@media (max-width: 1366px) {
  .section3 .cont .left .num .item > div span {
    font-size: 72.85714px;
  }
}

@media (max-width: 1289px) {
  .section3 .cont .left .num .item > div span {
    font-size: 67.14286px;
  }
}

@media (max-width: 1024px) {
  .section3 .cont .left .num .item > div span {
    font-size: 61.42857px;
  }
}

@media (max-width: 991px) {
  .section3 .cont .left .num .item > div span {
    font-size: 55.71429px;
  }
}

@media (max-width: 767px) {
  .section3 .cont .left .num .item > div span {
    font-size: 50px;
  }
}

.section3 .cont .left .num .item > div i {
  font-style: normal;
  display: inline-block;
  font-size: 32px;
  transform: translateY(-150%);
}

@media (max-width: 1699px) {
  .section3 .cont .left .num .item > div i {
    font-size: 30.28571px;
  }
}

@media (max-width: 1600px) {
  .section3 .cont .left .num .item > div i {
    font-size: 28.57143px;
  }
}

@media (max-width: 1366px) {
  .section3 .cont .left .num .item > div i {
    font-size: 26.85714px;
  }
}

@media (max-width: 1289px) {
  .section3 .cont .left .num .item > div i {
    font-size: 25.14286px;
  }
}

@media (max-width: 1024px) {
  .section3 .cont .left .num .item > div i {
    font-size: 23.42857px;
  }
}

@media (max-width: 991px) {
  .section3 .cont .left .num .item > div i {
    font-size: 21.71429px;
  }
}

@media (max-width: 767px) {
  .section3 .cont .left .num .item > div i {
    font-size: 20px;
  }
}

.section3 .cont .right {
  top: 50%;
  right: 8%;
  transition: transform .4s;
  transform: translateY(-50%);
  position: absolute;
  width: 45%;
  margin: 0;
  margin-top: 0;
}

@media (max-width: 991px) {
  .section3 .cont .right {
    transform: translateY(0);
  }
}

@media (max-width: 991px) {
  .section3 .cont .right {
    position: static;
  }
}

@media (max-width: 991px) {
  .section3 .cont .right {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .section3 .cont .right {
    width: 80%;
  }
}

@media (max-width: 991px) {
  .section3 .cont .right {
    margin: 0 auto;
  }
}

@media (max-width: 991px) {
  .section3 .cont .right {
    margin-top: 30px;
  }
}

.section3 .cont .right:hover {
  transform: translateY(-50%) scale(1.04);
}

@media (max-width: 991px) {
  .section3 .cont .right:hover {
    transform: translateY(0) scale(1.02);
  }
}

.section3 .cont .right img {
  display: block;
  width: 100%;
  height: auto;
  transition: transform .6s;
  transform-origin: center;
  transform: scale(0.95) translateY(5%);
}

@media (max-width: 1024px) {
  .section3 .cont .right img {
    transform: none;
  }
}

.section3.active .cont .right img {
  transform: scale(1) translateY(0);
  transition-delay: .2s;
}

.section4 {
  background-color: #000;
  position: relative;
  overflow: hidden;
}

.section4 .bg {
  position: absolute;
  top: 0;
  left: 0;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: .5;
  transition: all 2s;
  transform: scale(1.1);
}

@media (max-width: 1024px) {
  .section4 .bg {
    transform: scale(1);
  }
}

.section4 .index-pd {
  position: relative;
  z-index: 1;
}

.section4 .index-pd .top-text {
  color: #fff;
  margin-bottom: 80px;
}

@media (max-width: 1699px) {
  .section4 .index-pd .top-text {
    margin-bottom: 72.85714px;
  }
}

@media (max-width: 1600px) {
  .section4 .index-pd .top-text {
    margin-bottom: 65.71429px;
  }
}

@media (max-width: 1366px) {
  .section4 .index-pd .top-text {
    margin-bottom: 58.57143px;
  }
}

@media (max-width: 1289px) {
  .section4 .index-pd .top-text {
    margin-bottom: 51.42857px;
  }
}

@media (max-width: 1024px) {
  .section4 .index-pd .top-text {
    margin-bottom: 44.28571px;
  }
}

@media (max-width: 991px) {
  .section4 .index-pd .top-text {
    margin-bottom: 37.14286px;
  }
}

@media (max-width: 767px) {
  .section4 .index-pd .top-text {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 1366px) {
  .section4 .index-pd .top-text {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1699px) {
  .section4 .index-pd .top-text {
    margin-bottom: 47.14286px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1600px) {
  .section4 .index-pd .top-text {
    margin-bottom: 44.28571px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1366px) {
  .section4 .index-pd .top-text {
    margin-bottom: 41.42857px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1289px) {
  .section4 .index-pd .top-text {
    margin-bottom: 38.57143px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1024px) {
  .section4 .index-pd .top-text {
    margin-bottom: 35.71429px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 991px) {
  .section4 .index-pd .top-text {
    margin-bottom: 32.85714px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 767px) {
  .section4 .index-pd .top-text {
    margin-bottom: 30px;
  }
}

.section4 .index-pd .top-text .left {
  float: left;
}

.section4 .index-pd .top-text .right {
  float: right;
}

.section4 .index-pd .top-text .right a {
  display: inline-block;
  border-radius: 50px;
  color: #fff;
  transition: all .3s;
  border: 1px solid #fff;
  font-size: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 15px;
}

@media (max-width: 1699px) {
  .section4 .index-pd .top-text .right a {
    font-size: 15.71429px;
  }
}

@media (max-width: 1600px) {
  .section4 .index-pd .top-text .right a {
    font-size: 15.42857px;
  }
}

@media (max-width: 1366px) {
  .section4 .index-pd .top-text .right a {
    font-size: 15.14286px;
  }
}

@media (max-width: 1289px) {
  .section4 .index-pd .top-text .right a {
    font-size: 14.85714px;
  }
}

@media (max-width: 1024px) {
  .section4 .index-pd .top-text .right a {
    font-size: 14.57143px;
  }
}

@media (max-width: 991px) {
  .section4 .index-pd .top-text .right a {
    font-size: 14.28571px;
  }
}

@media (max-width: 767px) {
  .section4 .index-pd .top-text .right a {
    font-size: 14px;
  }
}

@media (max-width: 1699px) {
  .section4 .index-pd .top-text .right a {
    padding-top: 5px;
  }
}

@media (max-width: 1600px) {
  .section4 .index-pd .top-text .right a {
    padding-top: 5px;
  }
}

@media (max-width: 1366px) {
  .section4 .index-pd .top-text .right a {
    padding-top: 5px;
  }
}

@media (max-width: 1289px) {
  .section4 .index-pd .top-text .right a {
    padding-top: 5px;
  }
}

@media (max-width: 1024px) {
  .section4 .index-pd .top-text .right a {
    padding-top: 5px;
  }
}

@media (max-width: 991px) {
  .section4 .index-pd .top-text .right a {
    padding-top: 5px;
  }
}

@media (max-width: 767px) {
  .section4 .index-pd .top-text .right a {
    padding-top: 5px;
  }
}

@media (max-width: 1699px) {
  .section4 .index-pd .top-text .right a {
    padding-bottom: 5px;
  }
}

@media (max-width: 1600px) {
  .section4 .index-pd .top-text .right a {
    padding-bottom: 5px;
  }
}

@media (max-width: 1366px) {
  .section4 .index-pd .top-text .right a {
    padding-bottom: 5px;
  }
}

@media (max-width: 1289px) {
  .section4 .index-pd .top-text .right a {
    padding-bottom: 5px;
  }
}

@media (max-width: 1024px) {
  .section4 .index-pd .top-text .right a {
    padding-bottom: 5px;
  }
}

@media (max-width: 991px) {
  .section4 .index-pd .top-text .right a {
    padding-bottom: 5px;
  }
}

@media (max-width: 767px) {
  .section4 .index-pd .top-text .right a {
    padding-bottom: 5px;
  }
}

@media (max-width: 1699px) {
  .section4 .index-pd .top-text .right a {
    padding-left: 46.42857px;
  }
}

@media (max-width: 1600px) {
  .section4 .index-pd .top-text .right a {
    padding-left: 42.85714px;
  }
}

@media (max-width: 1366px) {
  .section4 .index-pd .top-text .right a {
    padding-left: 39.28571px;
  }
}

@media (max-width: 1289px) {
  .section4 .index-pd .top-text .right a {
    padding-left: 35.71429px;
  }
}

@media (max-width: 1024px) {
  .section4 .index-pd .top-text .right a {
    padding-left: 32.14286px;
  }
}

@media (max-width: 991px) {
  .section4 .index-pd .top-text .right a {
    padding-left: 28.57143px;
  }
}

@media (max-width: 767px) {
  .section4 .index-pd .top-text .right a {
    padding-left: 25px;
  }
}

@media (max-width: 1699px) {
  .section4 .index-pd .top-text .right a {
    padding-right: 46.42857px;
  }
}

@media (max-width: 1600px) {
  .section4 .index-pd .top-text .right a {
    padding-right: 42.85714px;
  }
}

@media (max-width: 1366px) {
  .section4 .index-pd .top-text .right a {
    padding-right: 39.28571px;
  }
}

@media (max-width: 1289px) {
  .section4 .index-pd .top-text .right a {
    padding-right: 35.71429px;
  }
}

@media (max-width: 1024px) {
  .section4 .index-pd .top-text .right a {
    padding-right: 32.14286px;
  }
}

@media (max-width: 991px) {
  .section4 .index-pd .top-text .right a {
    padding-right: 28.57143px;
  }
}

@media (max-width: 767px) {
  .section4 .index-pd .top-text .right a {
    padding-right: 25px;
  }
}

.section4 .index-pd .top-text .right a:hover {
  border: 1px solid #ff7800;
  background-color: #ff7800;
}

@media (max-width: 1699px) {
  .section4 .index-pd .top-text .right a {
    margin-top: 14.28571px;
  }
}

@media (max-width: 1600px) {
  .section4 .index-pd .top-text .right a {
    margin-top: 13.57143px;
  }
}

@media (max-width: 1366px) {
  .section4 .index-pd .top-text .right a {
    margin-top: 12.85714px;
  }
}

@media (max-width: 1289px) {
  .section4 .index-pd .top-text .right a {
    margin-top: 12.14286px;
  }
}

@media (max-width: 1024px) {
  .section4 .index-pd .top-text .right a {
    margin-top: 11.42857px;
  }
}

@media (max-width: 991px) {
  .section4 .index-pd .top-text .right a {
    margin-top: 10.71429px;
  }
}

@media (max-width: 767px) {
  .section4 .index-pd .top-text .right a {
    margin-top: 10px;
  }
}

.section4 .index-pd .cont .left {
  border-radius: 25px;
  overflow: hidden;
  background-color: #fff;
  transition: all .8s;
  opacity: 0;
  transform: translateX(-10%);
  width: 47%;
  float: left;
  margin-bottom: 0;
}

@media (max-width: 1024px) {
  .section4 .index-pd .cont .left {
    opacity: 1;
  }
}

@media (max-width: 1024px) {
  .section4 .index-pd .cont .left {
    transform: translateX(0);
  }
}

@media (max-width: 991px) {
  .section4 .index-pd .cont .left {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .section4 .index-pd .cont .left {
    float: none;
  }
}

@media (max-width: 991px) {
  .section4 .index-pd .cont .left {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .section4 .index-pd .cont .left {
    margin-bottom: 20px;
  }
}

.section4 .index-pd .cont .left:hover .item-img b {
  transform: scale(1.1);
}

.section4 .index-pd .cont .left:hover .item-text .title {
  color: #ff7800;
}

.section4 .index-pd .cont .left .item-img {
  height: 310px;
}

@media (max-width: 1600px) {
  .section4 .index-pd .cont .left .item-img {
    height: 290px;
  }
}

@media (max-width: 1366px) {
  .section4 .index-pd .cont .left .item-img {
    height: 270px;
  }
}

@media (max-width: 1024px) {
  .section4 .index-pd .cont .left .item-img {
    height: 250px;
  }
}

.section4 .index-pd .cont .left .item-text {
  position: relative;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 55px;
  padding-bottom: 30px;
}

@media (max-width: 1699px) {
  .section4 .index-pd .cont .left .item-text {
    padding-left: 37.14286px;
  }
}

@media (max-width: 1600px) {
  .section4 .index-pd .cont .left .item-text {
    padding-left: 34.28571px;
  }
}

@media (max-width: 1366px) {
  .section4 .index-pd .cont .left .item-text {
    padding-left: 31.42857px;
  }
}

@media (max-width: 1289px) {
  .section4 .index-pd .cont .left .item-text {
    padding-left: 28.57143px;
  }
}

@media (max-width: 1024px) {
  .section4 .index-pd .cont .left .item-text {
    padding-left: 25.71429px;
  }
}

@media (max-width: 991px) {
  .section4 .index-pd .cont .left .item-text {
    padding-left: 22.85714px;
  }
}

@media (max-width: 767px) {
  .section4 .index-pd .cont .left .item-text {
    padding-left: 20px;
  }
}

@media (max-width: 1699px) {
  .section4 .index-pd .cont .left .item-text {
    padding-right: 37.14286px;
  }
}

@media (max-width: 1600px) {
  .section4 .index-pd .cont .left .item-text {
    padding-right: 34.28571px;
  }
}

@media (max-width: 1366px) {
  .section4 .index-pd .cont .left .item-text {
    padding-right: 31.42857px;
  }
}

@media (max-width: 1289px) {
  .section4 .index-pd .cont .left .item-text {
    padding-right: 28.57143px;
  }
}

@media (max-width: 1024px) {
  .section4 .index-pd .cont .left .item-text {
    padding-right: 25.71429px;
  }
}

@media (max-width: 991px) {
  .section4 .index-pd .cont .left .item-text {
    padding-right: 22.85714px;
  }
}

@media (max-width: 767px) {
  .section4 .index-pd .cont .left .item-text {
    padding-right: 20px;
  }
}

@media (max-width: 1699px) {
  .section4 .index-pd .cont .left .item-text {
    padding-top: 51.42857px;
  }
}

@media (max-width: 1600px) {
  .section4 .index-pd .cont .left .item-text {
    padding-top: 47.85714px;
  }
}

@media (max-width: 1366px) {
  .section4 .index-pd .cont .left .item-text {
    padding-top: 44.28571px;
  }
}

@media (max-width: 1289px) {
  .section4 .index-pd .cont .left .item-text {
    padding-top: 40.71429px;
  }
}

@media (max-width: 1024px) {
  .section4 .index-pd .cont .left .item-text {
    padding-top: 37.14286px;
  }
}

@media (max-width: 991px) {
  .section4 .index-pd .cont .left .item-text {
    padding-top: 33.57143px;
  }
}

@media (max-width: 767px) {
  .section4 .index-pd .cont .left .item-text {
    padding-top: 30px;
  }
}

@media (max-width: 1699px) {
  .section4 .index-pd .cont .left .item-text {
    padding-bottom: 27.85714px;
  }
}

@media (max-width: 1600px) {
  .section4 .index-pd .cont .left .item-text {
    padding-bottom: 25.71429px;
  }
}

@media (max-width: 1366px) {
  .section4 .index-pd .cont .left .item-text {
    padding-bottom: 23.57143px;
  }
}

@media (max-width: 1289px) {
  .section4 .index-pd .cont .left .item-text {
    padding-bottom: 21.42857px;
  }
}

@media (max-width: 1024px) {
  .section4 .index-pd .cont .left .item-text {
    padding-bottom: 19.28571px;
  }
}

@media (max-width: 991px) {
  .section4 .index-pd .cont .left .item-text {
    padding-bottom: 17.14286px;
  }
}

@media (max-width: 767px) {
  .section4 .index-pd .cont .left .item-text {
    padding-bottom: 15px;
  }
}

.section4 .index-pd .cont .left .item-text .date {
  position: absolute;
  top: 0;
  background-color: #ff7800;
  color: #fff;
  transform: translateY(-50%);
  line-height: 1.2;
  border-radius: 15px;
  padding: 15px;
  font-size: 20px;
  left: 30px;
}

@media (max-width: 1699px) {
  .section4 .index-pd .cont .left .item-text .date {
    border-radius: 14px;
  }
}

@media (max-width: 1600px) {
  .section4 .index-pd .cont .left .item-text .date {
    border-radius: 13px;
  }
}

@media (max-width: 1366px) {
  .section4 .index-pd .cont .left .item-text .date {
    border-radius: 12px;
  }
}

@media (max-width: 1289px) {
  .section4 .index-pd .cont .left .item-text .date {
    border-radius: 11px;
  }
}

@media (max-width: 1024px) {
  .section4 .index-pd .cont .left .item-text .date {
    border-radius: 10px;
  }
}

@media (max-width: 991px) {
  .section4 .index-pd .cont .left .item-text .date {
    border-radius: 9px;
  }
}

@media (max-width: 767px) {
  .section4 .index-pd .cont .left .item-text .date {
    border-radius: 8px;
  }
}

@media (max-width: 1699px) {
  .section4 .index-pd .cont .left .item-text .date {
    padding: 14px;
  }
}

@media (max-width: 1600px) {
  .section4 .index-pd .cont .left .item-text .date {
    padding: 13px;
  }
}

@media (max-width: 1366px) {
  .section4 .index-pd .cont .left .item-text .date {
    padding: 12px;
  }
}

@media (max-width: 1289px) {
  .section4 .index-pd .cont .left .item-text .date {
    padding: 11px;
  }
}

@media (max-width: 1024px) {
  .section4 .index-pd .cont .left .item-text .date {
    padding: 10px;
  }
}

@media (max-width: 991px) {
  .section4 .index-pd .cont .left .item-text .date {
    padding: 9px;
  }
}

@media (max-width: 767px) {
  .section4 .index-pd .cont .left .item-text .date {
    padding: 8px;
  }
}

@media (max-width: 1699px) {
  .section4 .index-pd .cont .left .item-text .date {
    font-size: 18.85714px;
  }
}

@media (max-width: 1600px) {
  .section4 .index-pd .cont .left .item-text .date {
    font-size: 17.71429px;
  }
}

@media (max-width: 1366px) {
  .section4 .index-pd .cont .left .item-text .date {
    font-size: 16.57143px;
  }
}

@media (max-width: 1289px) {
  .section4 .index-pd .cont .left .item-text .date {
    font-size: 15.42857px;
  }
}

@media (max-width: 1024px) {
  .section4 .index-pd .cont .left .item-text .date {
    font-size: 14.28571px;
  }
}

@media (max-width: 991px) {
  .section4 .index-pd .cont .left .item-text .date {
    font-size: 13.14286px;
  }
}

@media (max-width: 767px) {
  .section4 .index-pd .cont .left .item-text .date {
    font-size: 12px;
  }
}

@media (max-width: 1699px) {
  .section4 .index-pd .cont .left .item-text .date {
    left: 27.85714px;
  }
}

@media (max-width: 1600px) {
  .section4 .index-pd .cont .left .item-text .date {
    left: 25.71429px;
  }
}

@media (max-width: 1366px) {
  .section4 .index-pd .cont .left .item-text .date {
    left: 23.57143px;
  }
}

@media (max-width: 1289px) {
  .section4 .index-pd .cont .left .item-text .date {
    left: 21.42857px;
  }
}

@media (max-width: 1024px) {
  .section4 .index-pd .cont .left .item-text .date {
    left: 19.28571px;
  }
}

@media (max-width: 991px) {
  .section4 .index-pd .cont .left .item-text .date {
    left: 17.14286px;
  }
}

@media (max-width: 767px) {
  .section4 .index-pd .cont .left .item-text .date {
    left: 15px;
  }
}

.section4 .index-pd .cont .left .item-text .date span {
  display: block;
  font-size: 22px;
}

@media (max-width: 1699px) {
  .section4 .index-pd .cont .left .item-text .date span {
    font-size: 20.85714px;
  }
}

@media (max-width: 1600px) {
  .section4 .index-pd .cont .left .item-text .date span {
    font-size: 19.71429px;
  }
}

@media (max-width: 1366px) {
  .section4 .index-pd .cont .left .item-text .date span {
    font-size: 18.57143px;
  }
}

@media (max-width: 1289px) {
  .section4 .index-pd .cont .left .item-text .date span {
    font-size: 17.42857px;
  }
}

@media (max-width: 1024px) {
  .section4 .index-pd .cont .left .item-text .date span {
    font-size: 16.28571px;
  }
}

@media (max-width: 991px) {
  .section4 .index-pd .cont .left .item-text .date span {
    font-size: 15.14286px;
  }
}

@media (max-width: 767px) {
  .section4 .index-pd .cont .left .item-text .date span {
    font-size: 14px;
  }
}

.section4 .index-pd .cont .left .item-text .title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 5px;
  transition: all .3s;
  font-size: 16px;
}

.section4 .index-pd .cont .left .item-text .desc {
  font-size: 14px;
  color: #585555;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  height: 45px;
}

.section4 .index-pd .cont .right {
  border-radius: 25px;
  overflow: hidden;
  background-color: #fff;
  transition: all .8s;
  opacity: 0;
  transform: translateX(10%);
  width: 47%;
  float: right;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (max-width: 1024px) {
  .section4 .index-pd .cont .right {
    opacity: 1;
  }
}

@media (max-width: 1024px) {
  .section4 .index-pd .cont .right {
    transform: translateX(0);
  }
}

@media (max-width: 991px) {
  .section4 .index-pd .cont .right {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .section4 .index-pd .cont .right {
    float: none;
  }
}

@media (max-width: 1699px) {
  .section4 .index-pd .cont .right {
    padding-left: 54.28571px;
  }
}

@media (max-width: 1600px) {
  .section4 .index-pd .cont .right {
    padding-left: 48.57143px;
  }
}

@media (max-width: 1366px) {
  .section4 .index-pd .cont .right {
    padding-left: 42.85714px;
  }
}

@media (max-width: 1289px) {
  .section4 .index-pd .cont .right {
    padding-left: 37.14286px;
  }
}

@media (max-width: 1024px) {
  .section4 .index-pd .cont .right {
    padding-left: 31.42857px;
  }
}

@media (max-width: 991px) {
  .section4 .index-pd .cont .right {
    padding-left: 25.71429px;
  }
}

@media (max-width: 767px) {
  .section4 .index-pd .cont .right {
    padding-left: 20px;
  }
}

@media (max-width: 1699px) {
  .section4 .index-pd .cont .right {
    padding-right: 54.28571px;
  }
}

@media (max-width: 1600px) {
  .section4 .index-pd .cont .right {
    padding-right: 48.57143px;
  }
}

@media (max-width: 1366px) {
  .section4 .index-pd .cont .right {
    padding-right: 42.85714px;
  }
}

@media (max-width: 1289px) {
  .section4 .index-pd .cont .right {
    padding-right: 37.14286px;
  }
}

@media (max-width: 1024px) {
  .section4 .index-pd .cont .right {
    padding-right: 31.42857px;
  }
}

@media (max-width: 991px) {
  .section4 .index-pd .cont .right {
    padding-right: 25.71429px;
  }
}

@media (max-width: 767px) {
  .section4 .index-pd .cont .right {
    padding-right: 20px;
  }
}

@media (max-width: 1699px) {
  .section4 .index-pd .cont .right {
    padding-top: 27.14286px;
  }
}

@media (max-width: 1600px) {
  .section4 .index-pd .cont .right {
    padding-top: 24.28571px;
  }
}

@media (max-width: 1366px) {
  .section4 .index-pd .cont .right {
    padding-top: 21.42857px;
  }
}

@media (max-width: 1289px) {
  .section4 .index-pd .cont .right {
    padding-top: 18.57143px;
  }
}

@media (max-width: 1024px) {
  .section4 .index-pd .cont .right {
    padding-top: 15.71429px;
  }
}

@media (max-width: 991px) {
  .section4 .index-pd .cont .right {
    padding-top: 12.85714px;
  }
}

@media (max-width: 767px) {
  .section4 .index-pd .cont .right {
    padding-top: 10px;
  }
}

@media (max-width: 1699px) {
  .section4 .index-pd .cont .right {
    padding-bottom: 27.14286px;
  }
}

@media (max-width: 1600px) {
  .section4 .index-pd .cont .right {
    padding-bottom: 24.28571px;
  }
}

@media (max-width: 1366px) {
  .section4 .index-pd .cont .right {
    padding-bottom: 21.42857px;
  }
}

@media (max-width: 1289px) {
  .section4 .index-pd .cont .right {
    padding-bottom: 18.57143px;
  }
}

@media (max-width: 1024px) {
  .section4 .index-pd .cont .right {
    padding-bottom: 15.71429px;
  }
}

@media (max-width: 991px) {
  .section4 .index-pd .cont .right {
    padding-bottom: 12.85714px;
  }
}

@media (max-width: 767px) {
  .section4 .index-pd .cont .right {
    padding-bottom: 10px;
  }
}

.section4 .index-pd .cont .right .item {
  position: relative;
  padding-left: 110px;
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (max-width: 1699px) {
  .section4 .index-pd .cont .right .item {
    padding-left: 102.85714px;
  }
}

@media (max-width: 1600px) {
  .section4 .index-pd .cont .right .item {
    padding-left: 95.71429px;
  }
}

@media (max-width: 1366px) {
  .section4 .index-pd .cont .right .item {
    padding-left: 88.57143px;
  }
}

@media (max-width: 1289px) {
  .section4 .index-pd .cont .right .item {
    padding-left: 81.42857px;
  }
}

@media (max-width: 1024px) {
  .section4 .index-pd .cont .right .item {
    padding-left: 74.28571px;
  }
}

@media (max-width: 991px) {
  .section4 .index-pd .cont .right .item {
    padding-left: 67.14286px;
  }
}

@media (max-width: 767px) {
  .section4 .index-pd .cont .right .item {
    padding-left: 60px;
  }
}

@media (max-width: 1699px) {
  .section4 .index-pd .cont .right .item {
    padding-top: 27.85714px;
  }
}

@media (max-width: 1600px) {
  .section4 .index-pd .cont .right .item {
    padding-top: 25.71429px;
  }
}

@media (max-width: 1366px) {
  .section4 .index-pd .cont .right .item {
    padding-top: 23.57143px;
  }
}

@media (max-width: 1289px) {
  .section4 .index-pd .cont .right .item {
    padding-top: 21.42857px;
  }
}

@media (max-width: 1024px) {
  .section4 .index-pd .cont .right .item {
    padding-top: 19.28571px;
  }
}

@media (max-width: 991px) {
  .section4 .index-pd .cont .right .item {
    padding-top: 17.14286px;
  }
}

@media (max-width: 767px) {
  .section4 .index-pd .cont .right .item {
    padding-top: 15px;
  }
}

@media (max-width: 1699px) {
  .section4 .index-pd .cont .right .item {
    padding-bottom: 27.85714px;
  }
}

@media (max-width: 1600px) {
  .section4 .index-pd .cont .right .item {
    padding-bottom: 25.71429px;
  }
}

@media (max-width: 1366px) {
  .section4 .index-pd .cont .right .item {
    padding-bottom: 23.57143px;
  }
}

@media (max-width: 1289px) {
  .section4 .index-pd .cont .right .item {
    padding-bottom: 21.42857px;
  }
}

@media (max-width: 1024px) {
  .section4 .index-pd .cont .right .item {
    padding-bottom: 19.28571px;
  }
}

@media (max-width: 991px) {
  .section4 .index-pd .cont .right .item {
    padding-bottom: 17.14286px;
  }
}

@media (max-width: 767px) {
  .section4 .index-pd .cont .right .item {
    padding-bottom: 15px;
  }
}

.section4 .index-pd .cont .right .item:not(:first-child) {
  border-top: 1px solid #eaeaea;
}

.section4 .index-pd .cont .right .item:hover .t .title {
  color: #ff7800;
}

.section4 .index-pd .cont .right .item .date {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background-color: #ff7800;
  color: #fff;
  line-height: 1.2;
  border-radius: 15px;
  padding: 15px;
  font-size: 20px;
}

@media (max-width: 1699px) {
  .section4 .index-pd .cont .right .item .date {
    border-radius: 14px;
  }
}

@media (max-width: 1600px) {
  .section4 .index-pd .cont .right .item .date {
    border-radius: 13px;
  }
}

@media (max-width: 1366px) {
  .section4 .index-pd .cont .right .item .date {
    border-radius: 12px;
  }
}

@media (max-width: 1289px) {
  .section4 .index-pd .cont .right .item .date {
    border-radius: 11px;
  }
}

@media (max-width: 1024px) {
  .section4 .index-pd .cont .right .item .date {
    border-radius: 10px;
  }
}

@media (max-width: 991px) {
  .section4 .index-pd .cont .right .item .date {
    border-radius: 9px;
  }
}

@media (max-width: 767px) {
  .section4 .index-pd .cont .right .item .date {
    border-radius: 8px;
  }
}

@media (max-width: 1699px) {
  .section4 .index-pd .cont .right .item .date {
    padding: 14px;
  }
}

@media (max-width: 1600px) {
  .section4 .index-pd .cont .right .item .date {
    padding: 13px;
  }
}

@media (max-width: 1366px) {
  .section4 .index-pd .cont .right .item .date {
    padding: 12px;
  }
}

@media (max-width: 1289px) {
  .section4 .index-pd .cont .right .item .date {
    padding: 11px;
  }
}

@media (max-width: 1024px) {
  .section4 .index-pd .cont .right .item .date {
    padding: 10px;
  }
}

@media (max-width: 991px) {
  .section4 .index-pd .cont .right .item .date {
    padding: 9px;
  }
}

@media (max-width: 767px) {
  .section4 .index-pd .cont .right .item .date {
    padding: 8px;
  }
}

@media (max-width: 1699px) {
  .section4 .index-pd .cont .right .item .date {
    font-size: 18.85714px;
  }
}

@media (max-width: 1600px) {
  .section4 .index-pd .cont .right .item .date {
    font-size: 17.71429px;
  }
}

@media (max-width: 1366px) {
  .section4 .index-pd .cont .right .item .date {
    font-size: 16.57143px;
  }
}

@media (max-width: 1289px) {
  .section4 .index-pd .cont .right .item .date {
    font-size: 15.42857px;
  }
}

@media (max-width: 1024px) {
  .section4 .index-pd .cont .right .item .date {
    font-size: 14.28571px;
  }
}

@media (max-width: 991px) {
  .section4 .index-pd .cont .right .item .date {
    font-size: 13.14286px;
  }
}

@media (max-width: 767px) {
  .section4 .index-pd .cont .right .item .date {
    font-size: 12px;
  }
}

.section4 .index-pd .cont .right .item .date span {
  display: block;
  font-size: 22px;
}

@media (max-width: 1699px) {
  .section4 .index-pd .cont .right .item .date span {
    font-size: 20.85714px;
  }
}

@media (max-width: 1600px) {
  .section4 .index-pd .cont .right .item .date span {
    font-size: 19.71429px;
  }
}

@media (max-width: 1366px) {
  .section4 .index-pd .cont .right .item .date span {
    font-size: 18.57143px;
  }
}

@media (max-width: 1289px) {
  .section4 .index-pd .cont .right .item .date span {
    font-size: 17.42857px;
  }
}

@media (max-width: 1024px) {
  .section4 .index-pd .cont .right .item .date span {
    font-size: 16.28571px;
  }
}

@media (max-width: 991px) {
  .section4 .index-pd .cont .right .item .date span {
    font-size: 15.14286px;
  }
}

@media (max-width: 767px) {
  .section4 .index-pd .cont .right .item .date span {
    font-size: 14px;
  }
}

.section4 .index-pd .cont .right .item .t .title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 5px;
  transition: all .3s;
  font-size: 16px;
}

.section4 .index-pd .cont .right .item .t .desc {
  font-size: 14px;
  color: #585555;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  height: 45px;
}

.section4.active .bg {
  transition-delay: .2s;
  transform: scale(1);
}

.section4.active .cont .left, .section4.active .cont .right {
  opacity: 1;
  transform: translateX(0);
  transition-delay: .2s;
}

.section5 {
  background-color: #f9f9f9;
  position: relative;
}

.section5::after {
  content: '';
  width: 35%;
  height: 100%;
  background-color: #edecec;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  display: block;
}

@media (max-width: 991px) {
  .section5::after {
    display: none;
  }
}

.section5 .index-pd {
  position: relative;
  z-index: 1;
}

.section5 .top-text {
  margin-bottom: 40px;
}

@media (max-width: 1699px) {
  .section5 .top-text {
    margin-bottom: 36.42857px;
  }
}

@media (max-width: 1600px) {
  .section5 .top-text {
    margin-bottom: 32.85714px;
  }
}

@media (max-width: 1366px) {
  .section5 .top-text {
    margin-bottom: 29.28571px;
  }
}

@media (max-width: 1289px) {
  .section5 .top-text {
    margin-bottom: 25.71429px;
  }
}

@media (max-width: 1024px) {
  .section5 .top-text {
    margin-bottom: 22.14286px;
  }
}

@media (max-width: 991px) {
  .section5 .top-text {
    margin-bottom: 18.57143px;
  }
}

@media (max-width: 767px) {
  .section5 .top-text {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 1366px) {
  .section5 .top-text {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1699px) {
  .section5 .top-text {
    margin-bottom: 19.28571px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1600px) {
  .section5 .top-text {
    margin-bottom: 18.57143px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1366px) {
  .section5 .top-text {
    margin-bottom: 17.85714px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1289px) {
  .section5 .top-text {
    margin-bottom: 17.14286px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1024px) {
  .section5 .top-text {
    margin-bottom: 16.42857px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 991px) {
  .section5 .top-text {
    margin-bottom: 15.71429px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 767px) {
  .section5 .top-text {
    margin-bottom: 15px;
  }
}

.section5 .top-text .left {
  float: left;
}

.section5 .top-text .right {
  float: right;
}

.section5 .top-text .right a {
  display: inline-block;
  border-radius: 50px;
  transition: all .3s;
  border: 1px solid #221f1f;
  font-size: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 15px;
}

@media (max-width: 1699px) {
  .section5 .top-text .right a {
    font-size: 15.71429px;
  }
}

@media (max-width: 1600px) {
  .section5 .top-text .right a {
    font-size: 15.42857px;
  }
}

@media (max-width: 1366px) {
  .section5 .top-text .right a {
    font-size: 15.14286px;
  }
}

@media (max-width: 1289px) {
  .section5 .top-text .right a {
    font-size: 14.85714px;
  }
}

@media (max-width: 1024px) {
  .section5 .top-text .right a {
    font-size: 14.57143px;
  }
}

@media (max-width: 991px) {
  .section5 .top-text .right a {
    font-size: 14.28571px;
  }
}

@media (max-width: 767px) {
  .section5 .top-text .right a {
    font-size: 14px;
  }
}

@media (max-width: 1699px) {
  .section5 .top-text .right a {
    padding-top: 5px;
  }
}

@media (max-width: 1600px) {
  .section5 .top-text .right a {
    padding-top: 5px;
  }
}

@media (max-width: 1366px) {
  .section5 .top-text .right a {
    padding-top: 5px;
  }
}

@media (max-width: 1289px) {
  .section5 .top-text .right a {
    padding-top: 5px;
  }
}

@media (max-width: 1024px) {
  .section5 .top-text .right a {
    padding-top: 5px;
  }
}

@media (max-width: 991px) {
  .section5 .top-text .right a {
    padding-top: 5px;
  }
}

@media (max-width: 767px) {
  .section5 .top-text .right a {
    padding-top: 5px;
  }
}

@media (max-width: 1699px) {
  .section5 .top-text .right a {
    padding-bottom: 5px;
  }
}

@media (max-width: 1600px) {
  .section5 .top-text .right a {
    padding-bottom: 5px;
  }
}

@media (max-width: 1366px) {
  .section5 .top-text .right a {
    padding-bottom: 5px;
  }
}

@media (max-width: 1289px) {
  .section5 .top-text .right a {
    padding-bottom: 5px;
  }
}

@media (max-width: 1024px) {
  .section5 .top-text .right a {
    padding-bottom: 5px;
  }
}

@media (max-width: 991px) {
  .section5 .top-text .right a {
    padding-bottom: 5px;
  }
}

@media (max-width: 767px) {
  .section5 .top-text .right a {
    padding-bottom: 5px;
  }
}

@media (max-width: 1699px) {
  .section5 .top-text .right a {
    padding-left: 46.42857px;
  }
}

@media (max-width: 1600px) {
  .section5 .top-text .right a {
    padding-left: 42.85714px;
  }
}

@media (max-width: 1366px) {
  .section5 .top-text .right a {
    padding-left: 39.28571px;
  }
}

@media (max-width: 1289px) {
  .section5 .top-text .right a {
    padding-left: 35.71429px;
  }
}

@media (max-width: 1024px) {
  .section5 .top-text .right a {
    padding-left: 32.14286px;
  }
}

@media (max-width: 991px) {
  .section5 .top-text .right a {
    padding-left: 28.57143px;
  }
}

@media (max-width: 767px) {
  .section5 .top-text .right a {
    padding-left: 25px;
  }
}

@media (max-width: 1699px) {
  .section5 .top-text .right a {
    padding-right: 46.42857px;
  }
}

@media (max-width: 1600px) {
  .section5 .top-text .right a {
    padding-right: 42.85714px;
  }
}

@media (max-width: 1366px) {
  .section5 .top-text .right a {
    padding-right: 39.28571px;
  }
}

@media (max-width: 1289px) {
  .section5 .top-text .right a {
    padding-right: 35.71429px;
  }
}

@media (max-width: 1024px) {
  .section5 .top-text .right a {
    padding-right: 32.14286px;
  }
}

@media (max-width: 991px) {
  .section5 .top-text .right a {
    padding-right: 28.57143px;
  }
}

@media (max-width: 767px) {
  .section5 .top-text .right a {
    padding-right: 25px;
  }
}

.section5 .top-text .right a:hover {
  color: #fff;
  border: 1px solid #ff7800;
  background-color: #ff7800;
}

@media (max-width: 1699px) {
  .section5 .top-text .right a {
    margin-top: 14.28571px;
  }
}

@media (max-width: 1600px) {
  .section5 .top-text .right a {
    margin-top: 13.57143px;
  }
}

@media (max-width: 1366px) {
  .section5 .top-text .right a {
    margin-top: 12.85714px;
  }
}

@media (max-width: 1289px) {
  .section5 .top-text .right a {
    margin-top: 12.14286px;
  }
}

@media (max-width: 1024px) {
  .section5 .top-text .right a {
    margin-top: 11.42857px;
  }
}

@media (max-width: 991px) {
  .section5 .top-text .right a {
    margin-top: 10.71429px;
  }
}

@media (max-width: 767px) {
  .section5 .top-text .right a {
    margin-top: 10px;
  }
}

.section5 .logo-list ul {
  width: calc(100% + 25px);
}

@media (max-width: 1024px) {
  .section5 .logo-list ul {
    width: calc(100% + 15px);
  }
}

.section5 .logo-list ul li {
  background-color: #fff;
  position: relative;
  float: left;
  transition: all .8s;
  overflow: hidden;
  opacity: 0;
  transform: translateY(10%);
  margin-top: 30px;
  height: 96px;
  margin-right: 25px;
  width: calc(16.66% - 25px);
}

@media (max-width: 1024px) {
  .section5 .logo-list ul li {
    opacity: 1;
  }
}

@media (max-width: 1024px) {
  .section5 .logo-list ul li {
    transform: translateY(0);
  }
}

@media (max-width: 1699px) {
  .section5 .logo-list ul li {
    margin-top: 27.85714px;
  }
}

@media (max-width: 1600px) {
  .section5 .logo-list ul li {
    margin-top: 25.71429px;
  }
}

@media (max-width: 1366px) {
  .section5 .logo-list ul li {
    margin-top: 23.57143px;
  }
}

@media (max-width: 1289px) {
  .section5 .logo-list ul li {
    margin-top: 21.42857px;
  }
}

@media (max-width: 1024px) {
  .section5 .logo-list ul li {
    margin-top: 19.28571px;
  }
}

@media (max-width: 991px) {
  .section5 .logo-list ul li {
    margin-top: 17.14286px;
  }
}

@media (max-width: 767px) {
  .section5 .logo-list ul li {
    margin-top: 15px;
  }
}

@media (max-width: 1600px) {
  .section5 .logo-list ul li {
    height: 85px;
  }
}

@media (max-width: 1366px) {
  .section5 .logo-list ul li {
    height: 75px;
  }
}

@media (max-width: 1024px) {
  .section5 .logo-list ul li {
    height: 65px;
  }
}

@media (max-width: 1024px) {
  .section5 .logo-list ul li {
    margin-right: 15px;
  }
}

@media (max-width: 1024px) {
  .section5 .logo-list ul li {
    width: calc(16.66% - 15px);
  }
}

@media (max-width: 991px) {
  .section5 .logo-list ul li {
    width: calc(25% - 15px);
  }
}

@media (max-width: 767px) {
  .section5 .logo-list ul li {
    width: calc(50% - 15px);
  }
}

.section5 .logo-list ul li:hover img {
  transform: translateX(-50%) translateY(-50%) scale(1.2);
}

.section5 .logo-list ul li img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  transition: all .3s;
}

.section5.active .logo-list ul li {
  opacity: 1;
  transform: translateY(0);
}

.section5.active .logo-list ul li:nth-child(-n+6) {
  transition-delay: .2s;
}

.section5.active .logo-list ul li:nth-child(n+6):nth-child(-n+12) {
  transition-delay: .3s;
}

.section5.active .logo-list ul li:nth-child(n+12):nth-child(-n+18) {
  transition-delay: .4s;
}

.section5.active .logo-list ul li:nth-child(n+18):nth-child(-n+124) {
  transition-delay: .5s;
}

@keyframes scrollrain {
  0% {
    transform: scaleY(0);
    transform-origin: top;
  }
  50% {
    transform: scaleY(1);
    transform-origin: top;
  }
  51% {
    transform-origin: bottom;
  }
  100% {
    transform: scaleY(0);
    transform-origin: bottom;
  }
}
