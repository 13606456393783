@import "../../assets/styles/utils";

*{
  &::-webkit-scrollbar {
    width: 0px !important;   
    scrollbar-width: thin; 
  }
}
.wow{
  @include res(visibility,visible !important,(md:hidden));
}
.index-pd{
  @include res(padding-top,100px,(md:70px,xs:50px));
  @include res(padding-left,120px, 20 / 120);
  @include res(padding-right,160px, 20 / 160);
  @include res(padding-bottom,0px,(md:60px,xs:30px));
}
.index-common-title{
  @include res(font-size,20px, 16 / 20);
  h1{
    @include res(font-weight,normal,(sm:bold));
    @include res(font-size,32px, 24 / 32);
    @include res(margin-bottom,10px, 5 / 10);
  }
}
.section{
  overflow: hidden;
  @include res(height,100vh,(md:auto));
}
.index-banner{
  overflow: hidden;
  position: relative;
  @include res(height,100vh);
  .swiper{
    height: 100%;
    overflow: hidden;
    .swiper-slide{
      overflow: hidden;
      position: relative;
      &.ani-slide{
        b{
          transform: scale(1);
        }
        .pc{
          @include res(display,block,(xs:none));
        }
        .mobile{
          @include res(display,none,(xs:block));
        }
        .t{
          opacity: 1;
          margin-top: 0;
          
        }
      }
      &::after{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        background-color: rgba(0,0,0,.5);
      }
      .video{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        @include res(display,block,(md:none));
        video{
          width: 100%;
          height: 100%;
          transform: scale(1.2);
          transform-origin: center;
          @media screen and(max-width:1366px) {
            transform: scale(1.3);
          }
        }
      }
      b{
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transform-origin: center;
        background-size: cover;
        background-position: center;
        transform: scale(1.2);
        transition: all 5s;
        z-index: 0;
      }
      .t{
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 3;
        color: #fff;
        line-height: 1.3;
        transform: translate3d(-50%,-50%,0);
        transition: all 1s;
        opacity: 0;
        transition-delay: .2s;
        width: 100%;
        box-sizing: border-box;
        @include res(padding-left,160px, 50 / 160);
        @include res(margin-top,100px);
        @include res(font-size,54px, 20 / 54);
        @media screen and (max-width:767px) {
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }
  }
  .sroll-icon{
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    white-space: nowrap;
    z-index: 2;
    @include res(display,block,(md:none));
    @include res(margin-left,80px, 25 / 80);
    &::before{
      content: '';
      display: block;
      width: 1px;
      position: absolute;
      left: 50%;
      bottom: 200%;
      background-color: #fff;
      animation: scrollrain 3s infinite;
      @include res(height,80px, 40 / 80);
    }
  }
}

.section1{
  background-color: $color-main-bg;
  position: relative;
  .bg-img{
    height: 100%;
    background-position: center;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    @include res(width,55.2%,(md:100%));
    &::before{
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,.6);
      position: absolute;
      top: 0;
      left: 0;
    }
    &::after{
      content: '';
      display: block;
      width: 50%;
      height: 100%;
      background-image: linear-gradient(left,rgba(0,0,0,.8),rgba(0,0,0,.0));
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .title-t{
    position: relative;
    z-index: 2;
    @extend %clearfix;
    @include res(padding-left,120px, 20 / 120);
    @include res(padding-right,160px, 20 / 160);
    @include res(padding-top,100px,(md:100px,xs:50px));
    @include res(margin-bottom,20px, 10 / 20);
    .left{
      @include res(color,#fff);
      @include res(float,left,(xs:none));
      @include res(width,60%,(xs:100%));
    }
    .right{
      line-height: 1.4;
      font-weight: normal;
      @include res(color,#221f1f,(md:#fff));
      @include res(float,right,(xs:none));
      @include res(width,40%,(xs:100%));
      @include res(font-size,32px, 16 / 32);
      @include res(margin-top,0,(xs:20px));
    }
  }
  .cont{
    position: relative;
    z-index: 2;
    @extend %clearfix;
    @include res(padding-left,120px, 20 / 120);
    @include res(padding-bottom,null,(md:100px,xs:60px));
    .left-t{
      color: #fff;
      @include res(float,left,(md:none));
      @include res(width,26%,(mmd:34%,md:100%));
      @include res(margin-top,80px, 40 / 80);
      @include res(padding-right,0,(md:10%));
      @media screen and(max-width:1366px) {
        @include res(margin-top,40px, 20 / 40);
      }
      .title{
        @include res(font-size,20px, 18 / 20);
        @include res(margin-bottom, 10px, 5 / 10);
      }
      .desc{
        font-size: 16px;
        @include res(margin-bottom, 35px, 15 / 35);
      }
      .p{
        font-size: 14px;
        p{
          position: relative;
          @include res(padding-left,30px);
          @include res(margin-bottom, 10px, 5 / 10);
          &::before{
            content: '\e633';
            font-family: 'iconfont';
            position: absolute;
            left: 0;
            color: $color-main;
            @include res(top,-5px);
            @include res(font-size, 18px, 16 / 18);
          }
        }
      }
      .link{
        @include res(display,flex);
        @include res(margin-top, 60px, 30 / 60);
        @media screen and (max-width:1366px) {
          @include res(margin-top, 30px, 20 / 30);
        }
        a{
          flex-shrink: 0;
        }
        a:first-child{
          @include res(margin-right,25px, 15 / 25);
        }
      }
    }
    .right-swiper{
      position: relative;
      @include res(float,right,(md:none));
      @include res(width,68%,(mmd:65%,md:100%));
      @include res(margin-top,null,(md:40px));
      @media screen and (max-width:1024px) {
        @include res(padding-right,50px, 30 / 50);
      }
      .swiper{
        overflow: hidden;
        @include res(padding-left,50px, 30 / 50);
        @include res(padding-right,50px, 30 / 50);
        @media screen and (max-width:1024px) {
          padding-left:0;
          padding-right: 0;
        }
        .swiper-slide{
          @include res(padding-top,20px);
          @include res(padding-bottom,20px);
          .item{
            background-color: #fff;
            transition: box-shadow .3s,transform 1s,opacity 1s;
            @include res(transform,translateX(5%),(md:translateX(0)));
            @include res(opacity,0,(md:1));
            @include res(border-radius,24px, 20 / 24);
            @include res(padding-top,60px, 20 / 60);
            @include res(padding-bottom,70px, 20 / 70);
            @include res(padding-left,40px, 20 / 40);
            @include res(padding-right,40px, 20 / 40);
            @media screen and (max-width:1366px) {
              @include res(padding-top,40px, 20 / 40);
              @include res(padding-bottom,40px, 20 / 40);
            }
            &:hover{
              box-shadow: 0px 0px 20px rgba(0,0,0,.1);
              .item-link{
                opacity: 1;
              }
            }
            .item-img{
              @include res(margin-bottom,80px, 30 / 80);
              @media screen and (max-width:1366px) {
                @include res(margin-bottom,20px, 20 / 20);
              }
              img{
                display: block;
                height: auto;
                @include res(width,120px, 100 / 120);
                @media screen and(max-width:1366px) {
                  @include res(width,100px, 70 / 100);
                }
              }
            }
            .item-title{
              font-size: 16px;
              @include res(margin-bottom,25px, 15 / 25);
            }
            .item-desc{
              @include res(height,50px,(xs:100px));
            }
            .item-link{
              transition: all .3s;
              @include res(opacity,0,(md:1));
              @include res(margin-top,50px, 20 / 50);
            }
          }
        }
      }
      .swiper-button-next,.swiper-button-prev{
        background-image: none;
        width: auto;
        height: auto;
        font-weight: lighter;
        top: auto;
        bottom: -10%;
        @media screen and (max-width:1366px) {
          bottom: -8%;
        }
        &::after{
          font-family: 'iconfont';
          line-height: 1;
          color: #fff;
          transition: all .3s;
          @include res(font-size, 70px, 50 / 70);
        }
        &:hover{
          &::after{
            color: $color-main;
          }
        }
      }
      .swiper-button-next{
        right: auto;
        @include res(left,110px, 80 / 110);
        @media screen and (max-width:1024px) {
          left: 45%;
          right: auto;
        }
        &::after{
          content: '\e60d';
        }
      }
      .swiper-button-prev{
        @include res(left,40px, 20 / 40);
        @media screen and (max-width:1024px) {
          right: 55%;
          left: auto;
        }
        &::after{
          content: '\eb90';
        }
      }
    }
  }
  &.active{
    .cont{
      .right-swiper{
        .swiper .swiper-slide{
          .item{
            opacity: 1;
            transform: translateX(0);
          }
          &:nth-child(1){
            .item{transition-delay: .2s;}
            
          }
          &:nth-child(2){
            .item{transition-delay: .4s;}
          }
        } 
      }
    }
  }
}

.section2{
  background-color: #000;
  position: relative;
  .bg-swiper{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: .5;
    transform-origin: center;
    transition: transform .8s;
    @include res(transform,scale(0.9),(md:scale(1)));
    .swiper-container{
      height: 100%;
      .swiper-slide{
        b{
          display: block;
          width: 100%;
          height: 100%;
          background-position: center;
          background-size: cover;
          transform: scale(.7);
          transform-origin: center;
          transition: transform 1.5s;
        }
        &.swiper-slide-active{
          b{
            transform: scale(1);
          }
        }
      }
    }
  }
  .cont{
    position: relative;
    z-index: 1;
    color: #fff;
    @include res(margin-bottom,100px,(mmd:20px,md:0));
    @media screen and (max-width:1366px) {
      @include res(margin-bottom,20px,(md:0));
    }
    .top-text{
      @extend %clearfix;
      @include res(margin-bottom,125px, 50 / 125);
      @media screen and (max-width:1366px) {
        @include res(margin-bottom,70px, 30 / 70);
      }
      .left{
        @include res(float,left);
      }
      .right{
        @include res(float,right);
        a{
          display: inline-block;
          border-radius: 50px;
          color: #fff;
          transition: all .3s;
          border: 1px solid #fff;
          @include res(font-size, 16px, 14 / 16);
          @include res(padding-top,5px, 5 / 5);
          @include res(padding-bottom,5px, 5 / 5);
          @include res(padding-left,50px, 25 / 50);
          @include res(padding-right,50px, 25 / 50);
          &:hover{
            border: 1px solid $color-main;
            background-color: $color-main;
          }
          @include res(margin-top,15px, 10 / 15);
        }
      }
    }
    .swiper-cont{
      @extend %clearfix;
      .left{
        @include res(float,left,(sm:none));
        @include res(width,330px,(sm:100%));
        .swiper{
          .swiper-slide{
            >div{
              opacity: 0;
              transition: transform 1s, opacity 1s;
              transform: translateY(20%);
            }
            &.swiper-slide-active>div{
              opacity: 1;
              transform: translateY(0);
            }
            .title{
              @include res(font-size, 20px, 18 / 20);
              @include res(margin-bottom,10px);
            }
            .desc{
              font-size: 14px;
              @include res(margin-bottom,25px, 15 / 25);
            }
            .link{
              font-size: 14px;
              @include res(margin-bottom,130px, 50 / 130);
              >div{
                margin-bottom: 10px;
              }
              a{
                display: inline-block;
                background-color: rgba(0,0,0,.2);
                border-radius: 50px;
                transition: all .3s;
                @include res(padding-left,20px);
                @include res(padding-right,20px);
                @include res(padding-top,8px);
                @include res(padding-bottom,8px);
                .iconfont{
                  font-size: 14px;
                  color: $color-main;
                  transition: all .3s;
                  display: inline-block;
                  @include res(margin-left,25px);
                }
                &:hover{
                  background-color: rgba(0,0,0,.6);
                  .iconfont{
                    transform: translateX(5px);
                  }
                }
              }
            }
          }
        }
        .swiper-button{
          align-items: center;
          justify-content: center;
          @include res(display,none,(sm:flex));
          @include res(margin-top,100px, 50 / 100);
          .swiper-button-prev,.swiper-button-next{
            position: static;
            background-image: none;
            width: auto;
            height: auto;
            &::after{
              font-family: 'iconfont';
              line-height: 1;
              color: #fff;
              transition: all .3s;
              @include res(font-size, 70px, 50 / 70);
            }
            &:hover{
              &::after{
                color: $color-main;
              }
            }
          }
          .swiper-button-next{
            &::after{
              content: '\e60d';
            }
          }
          .swiper-button-prev{
            &::after{
              content: '\eb90';
            }
          }
        }
      }
      .right{
        overflow: hidden;
        border-radius: 25px;
        position: relative;
        background-color: #fff;
        color: #3d3a39;
        transition: all 1s;
        @include res(opacity,0,(md:1));
        @include res(transform,translateY(10%),(md:translateY(0%)));
        @include res(width,400px,(mmmd:350px,md:300px));
        @include res(float,right);
        @include res(display,block,(sm:none));
        >div{
          position: relative;
          z-index: 1;
        }
        .item{
          cursor: pointer;
          @extend %clearfix;
          @include res(padding-top,50px, 20 / 50);
          @include res(padding-bottom,50px, 20 / 50);
          @media screen and(max-width:1366px) {
            @include res(padding-top,30px, 20 / 30);
            @include res(padding-bottom,30px, 20 / 30);
          }
          &.active{
            img{
              filter: brightness(100%);
            }
            p{
              color: #fff;
            }
          }
          img{
            float: left;
            width: 45px;
            height: 45px;
            filter: brightness(30%);
            transition: all .5s;
            @include res(margin-left,80px, 20 / 80);
            @include res(margin-right,30px, 20 / 30);
          }
          p{
            line-height: 45px;
            white-space: nowrap;
            float: left;
            transition: all .5s;
            @include res(font-size, 20px, 18 / 20);
          }
        }
        .bg-trank{
          position: absolute;
          z-index: 0;
          top: 0;
          width: 100%;
          background-color: $color-main;
          height: 33.33%;
          transition: all .5s;
        }
      }
    }
  }
  &.active{
    .bg-swiper{
      transform: scale(1);
      transition-delay: .1s;
    }
    .cont .right{
      opacity: 1;
      transform: translateY(0);
      transition-delay: .2s;
    }
  }
}

.section3{
  position: relative;
  &::after{
    content: '';
    width: 35%;
    height: 100%;
    background-color: $color-main-bg;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    @include res(display,block,(sm:none));
  }
  .bg-canvas{
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 60%;
    bottom: 0;
    left: 0;
    overflow: hidden;
    canvas{
      width: 100%;
      height: 100%;
      pointer-events: none;
      touch-callout: none;
      transform: translateY(40%);
    }
  }
  .cont{
    position: relative;
    z-index: 2;
    .left{
      @include res(width,40%,(sm:100%));
      .top-text{
        .more-btn{
          a{
            display: inline-block;
            border-radius: 50px;
            color: $color-main;
            transition: all .3s;
            border: 1px solid $color-main;
            @include res(font-size, 16px, 14 / 16);
            @include res(padding-top,5px, 5 / 5);
            @include res(padding-bottom,5px, 5 / 5);
            @include res(padding-left,50px, 25 / 50);
            @include res(padding-right,50px, 25 / 50);
            &:hover{
              color: #fff;
              background-color: $color-main;
            }
            @include res(margin-top,15px, 10 / 15);
          }
        }
      }
      .num{
        @extend %clearfix;
        @include res(margin-top,100px, 30 / 100);
        @media screen and(max-width:1366px) {
          @include res(margin-top,50px, 30 / 50);
        }
        .item{
          float: left;
          border: 1px solid #f2f2f2;
          border-radius: 10px;
          background-color: #fff;
          text-align: center;
          @include res(font-size, 20px, 16 / 20);
          @include res(margin-top,20px, 10 / 20);
          @include res(width,45%,(sm:23%,xs:45%));
          @include res(margin-right,5%,(sm:2%,xs:5%));
          @include res(padding-top,30px, 20 / 30);
          @include res(padding-bottom,30px, 20 / 30);
          >div{
            color: $color-main;
            font-weight: bold;
            line-height: 1;
            @include res(margin-bottom,15px, 10 / 15);
            span{
              @include res(font-size,90px, 50 / 90);
            }
            i{
              font-style: normal;
              display: inline-block;
              @include res(font-size,32px, 20 / 32);
              @include res(transform,translateY(-150%));
            }
          }
        }
      }
    }
    .right{
      top: 50%;
      right: 8%;
      transition: transform .4s;
      @include res(transform,translateY(-50%),(sm:translateY(0)));
      @include res(position,absolute,(sm:static));
      @include res(width,45%,(sm:50%,xs:80%));
      @include res(margin,0,(sm:0 auto));
      @include res(margin-top,0,(sm:30px));
      &:hover{
        @include res(transform,translateY(-50%) scale(1.04),(sm:translateY(0) scale(1.02)));
      }
      img{
        display: block;
        width: 100%;
        height: auto;
        transition: transform .6s;
        transform-origin: center;
        @include res(transform,scale(.95) translateY(5%),(md:none));
      }
    }
  }
  &.active{
    .cont .right img{
      transform: scale(1) translateY(0);
      transition-delay: .2s;
    }
  }
}
.section4{
  background-color: #000;
  position: relative;
  overflow: hidden;
  .bg{
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: .5;
    transition: all 2s;
    @include res(transform,scale(1.1),(md:scale(1)));
  }
  .index-pd{
    position: relative;
    z-index: 1;
    .top-text{
      color: #fff;
      @extend %clearfix;
      @include res(margin-bottom,80px, 30 / 80);
      @media screen and(max-width:1366px) {
        @include res(margin-bottom,50px, 30 / 50);
      }
      .left{
        @include res(float,left);
      }
      .right{
        @include res(float,right);
        a{
          display: inline-block;
          border-radius: 50px;
          color: #fff;
          transition: all .3s;
          border: 1px solid #fff;
          @include res(font-size, 16px, 14 / 16);
          @include res(padding-top,5px, 5 / 5);
          @include res(padding-bottom,5px, 5 / 5);
          @include res(padding-left,50px, 25 / 50);
          @include res(padding-right,50px, 25 / 50);
          &:hover{
            border: 1px solid $color-main;
            background-color: $color-main;
          }
          @include res(margin-top,15px, 10 / 15);
        }
      }
    }
    .cont{
      @extend %clearfix;
      .left{
        border-radius: 25px;
        overflow: hidden;
        background-color: #fff;
        transition: all .8s;
        @include res(opacity,0,(md:1));
        @include res(transform,translateX(-10%),(md:translateX(0)));
        @include res(width,47%,(sm:100%));
        @include res(float,left,(sm:none));
        @include res(margin-bottom,0,(sm:30px,xs:20px));
        &:hover{
          .item-img b{
            transform: scale(1.1);
          }
          .item-text .title{
            color: $color-main;
          }
        }
        .item-img{
          @include res(height,310px,(lg:290px,mmmd:270px,md:250px));
        }
        .item-text{
          position: relative;
          @include res(padding-left,40px, 20 / 40);
          @include res(padding-right,40px, 20 / 40);
          @include res(padding-top,55px, 30 / 55);
          @include res(padding-bottom,30px, 15 / 30);
          .date{
            position: absolute;
            top: 0;
            background-color: $color-main;
            color: #fff;
            transform: translateY(-50%);
            line-height: 1.2;
            @include res(border-radius,15px, 8 / 15);
            @include res(padding,15px, 8 / 15);
            @include res(font-size,20px, 12 / 20);
            @include res(left, 30px, 15 / 30);
            span{
              display: block;
              @include res(font-size,22px, 14 / 22);
            }
          } 
          .title{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-bottom: 5px;
            transition: all .3s;
            font-size: 16px;
          }
          .desc{
            font-size: 14px;
            color: $color-desc;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            @include res(height,45px);
          }
        }
      }
      .right{
        border-radius: 25px;
        overflow: hidden;
        background-color: #fff;
        transition: all .8s;
        @include res(opacity,0,(md:1));
        @include res(transform,translateX(10%),(md:translateX(0)));
        @include res(width,47%,(sm:100%));
        @include res(float,right,(sm:none));
        @include res(padding-left,60px,20 / 60);
        @include res(padding-right,60px,20 / 60);
        @include res(padding-top,30px, 10 / 30);
        @include res(padding-bottom,30px, 10 / 30);
        .item{
          position: relative;
          @include res(padding-left,110px, 60 / 110);
          @include res(padding-top,30px, 15 / 30);
          @include res(padding-bottom,30px, 15 / 30);
          &:not(:first-child){
            border-top: 1px solid #eaeaea;
          }
          &:hover{
            .t .title{
              color: $color-main;
            }
          }
          .date{
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            background-color: $color-main;
            color: #fff;
            line-height: 1.2;
            @include res(border-radius,15px, 8 / 15);
            @include res(padding,15px, 8 / 15);
            @include res(font-size,20px, 12 / 20);
            span{
              display: block;
              @include res(font-size,22px, 14 / 22);
            }
          }
          .t{
            .title{
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              margin-bottom: 5px;
              transition: all .3s;
              font-size: 16px;
            }
            .desc{
              font-size: 14px;
              color: $color-desc;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              @include res(height,45px);
            }
          }
        }
      }
    }
  }
  &.active{
    .bg{
      transition-delay: .2s;
      @include res(transform,scale(1));
    }
    .cont{
      .left,.right{
        opacity: 1;
        transform: translateX(0);
        transition-delay: .2s;
      }
    }
  }
}

.section5{
  background-color: #f9f9f9;
  position: relative;
  &::after{
    content: '';
    width: 35%;
    height: 100%;
    background-color: $color-main-bg;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
    @include res(display,block,(sm:none));
  }
  .index-pd{
    position: relative;
    z-index: 1;
  }
  .top-text{
    @extend %clearfix;
    @include res(margin-bottom,40px, 15 / 40);
    @media screen and(max-width:1366px) {
      @include res(margin-bottom,20px, 15 / 20);
    }
    .left{
      @include res(float,left);
    }
    .right{
      @include res(float,right);
      a{
        display: inline-block;
        border-radius: 50px;
        transition: all .3s;
        border: 1px solid #221f1f;
        @include res(font-size, 16px, 14 / 16);
        @include res(padding-top,5px, 5 / 5);
        @include res(padding-bottom,5px, 5 / 5);
        @include res(padding-left,50px, 25 / 50);
        @include res(padding-right,50px, 25 / 50);
        &:hover{
          color: #fff;
          border: 1px solid $color-main;
          background-color: $color-main;
        }
        @include res(margin-top,15px, 10 / 15);
      }
    }
  }
  .logo-list{
    ul{
      @include res(width,calc(100% + 25px),(md:calc(100% + 15px)));
      @extend %clearfix;
      li{
        background-color: #fff;
        position: relative;
        float: left;
        transition: all .8s;
        overflow: hidden;
        @include res(opacity,0,(md:1));
        @include res(transform,translateY(10%),(md:translateY(0)));
        @include res(margin-top, 30px, 15 / 30);
        @include res(height,96px,(lg:85px,mmmd:75px,md:65px));
        @include res(margin-right,25px,(md:15px));
        @include res(width,calc(16.66% - 25px),(md:calc(16.66% - 15px),sm:calc(25% - 15px),xs:calc(50% - 15px)));
        &:hover{
          img{
            transform: translateX(-50%) translateY(-50%) scale(1.2);
          }
        }
        img{
          display: block;
          max-width: 100%;
          max-height: 100%;
          width: auto;
          height: auto;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          transition: all .3s;
        }
      }
    }
  }
  &.active{
    .logo-list ul li{
      opacity: 1;
      transform: translateY(0);
      &:nth-child(-n+6){
        transition-delay: .2s;
      }
      &:nth-child(n+6):nth-child(-n+12){
        transition-delay: .3s;
      }
      &:nth-child(n+12):nth-child(-n+18){
        transition-delay: .4s;
      }
      &:nth-child(n+18):nth-child(-n+124){
        transition-delay: .5s;
      }
    }
  }
}
@keyframes scrollrain{
  0%{
      transform: scaleY(0);
      transform-origin: top;
  }
  50%{
      transform: scaleY(1);
      transform-origin: top;
  }
  51%{
      transform-origin: bottom;
  }
  100%{
      transform: scaleY(0);
      transform-origin: bottom;
  }
}